import styled from "styled-components";
import Button from "../../../components/Button/MainButton";
import { Column, Row } from "../../../components/Layout";
import Marquee from "../../../components/Marquee";
import Spacer from "../../../components/Spacer";
import downloadIcon from "../../../public/images/icons/download.svg";
import Meme1 from "../../../public/images/memes/meme1.png";
import Meme2 from "../../../public/images/memes/meme2.png";
import Meme3 from "../../../public/images/memes/meme3.png";
import Meme4 from "../../../public/images/memes/meme4.png";
import Meme5 from "../../../public/images/memes/meme5.png";
import Meme6 from "../../../public/images/memes/meme6.png";
import Meme7 from "../../../public/images/memes/meme7.png";
import Meme8 from "../../../public/images/memes/meme8.png";
import useMatchBreakPoints from "../../../utils/useMatchBreakPoints";

const IMAGES_DATA = [Meme1, Meme2, Meme3, Meme4, Meme5, Meme6, Meme7, Meme8];

const StyledRow = styled(Row)`
  height: 100%;
  width: 100%;
  padding: 24px;
  overflow-y: auto;
  /* Scrollbar */
  &::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }
  &::-webkit-scrollbar-thumb {
    background: transparent;
    border-radius: 8px;
  }
  &::-webkit-scrollbar-track {
    border-radius: 10px;
  }
  &:hover {
    &::-webkit-scrollbar-thumb {
      background: #6ba2b066;
    }
  }
`;

const StyledImg = styled.img`
  border-radius: 16px;
  width: 240px;
  height: 240px;
  padding: 24px 24px 0px 24px;
  // background: url(<path-to-image>), lightgray 50% / cover no-repeat;
  @media (max-width: 1200px) {
    width: 160px;
    height: 160px;
  }
`;

const StyledMarquee = styled(Marquee)`
  width: 100%;
  mask-image: linear-gradient(
    to left,
    transparent,
    black 80px,
    black calc(100% - 80px),
    transparent
  );
  -webkit-mask-image: linear-gradient(
    to left,
    transparent,
    black 80px,
    black calc(100% - 80px),
    transparent
  );
  border-radius: 12px;
`;

function DownloadMemes() {
  const { isTablet } = useMatchBreakPoints();
  return (
    <Row>
      <Spacer />
      <Column width="100%" justify="center">
        <StyledMarquee
          data={IMAGES_DATA.map((imgUrl) => (
            <StyledImg src={imgUrl} />
          ))}
        />
        <Row width="100%" justify="center" gap="10px" mt="16px">
          <a
            href={"./gib-memes.zip"}
            target="_blank"
            style={{ textDecoration: "none" }}
          >
            <Button
              isPrimary
              textProps={{ textTransform: "uppercase", bold: true }}
              endIcon={<img src={downloadIcon} />}
            >
              DOWNLOAD MEMES
            </Button>
          </a>
        </Row>
        <Spacer />
        <Spacer />
      </Column>
    </Row>
  );
}

export default DownloadMemes;
