import styled from "styled-components";
import coinmiddle from "../../../public/images/tokenomics/coin-middle.png";
import coinright1 from "../../../public/images/tokenomics/coin-right1.png";
import coinright2 from "../../../public/images/tokenomics/coin-right2.png";
import coinright3 from "../../../public/images/tokenomics/coin-right3.png";
import { CoinBg } from "../../../components/CoinBackground";
import { Column } from "../../../components/Column/Column";
import UnderlineIcon, { UnderlineIcon3 } from "../../../components/Icons";
import Image from "../../../components/Image";
import { Row } from "../../../components/Layout";
import Spacer from "../../../components/Spacer";
import { Text } from "../../../components/Text";
import avtar4 from "../../../public/images/avtar/avtar4.png";
import coin1 from "../../../public/images/tokenomics/coin1.png";
import coin2 from "../../../public/images/tokenomics/coin2.png";
import coin3 from "../../../public/images/tokenomics/coin3.png";
import useMatchBreakPoints from "../../../utils/useMatchBreakPoints";

const ToknomicsWrapper = styled.div`
  display: flex;
  margin: 24px;
  justify-content: center;
  align-items: center;
`;

const MainContainer = styled.div`
  display: flex;
  width: 100%;
  max-width: 1024px;
  padding: 24px;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: 1200px) {
    padding: 80px 0px;
  }
`;

const Container = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 100px;
  @media screen and (max-width: 1200px) {
    display: flex;
    flex-direction: column;
    gap: 20px;
    flex: 1 0 0;
    padding: 0px;
  }
`;

const RightColumn = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  align-self: stretch;
  gap: 5px;
  @media screen and (max-width: 1200px) {
    display: flex;
    gap: 4px;
    flex: 1 0 0;
  }
`;

const TOKENOMICS_DATA: {
  title: string;
  value: string;
  isBlue?: boolean;
  desc?: string;
  isdesc?: boolean;
}[] = [
  {
    title: "Public",
    value: "80%",
    isBlue: true,
  },
  {
    title: "Core Contributors",
    value: "20%",
    desc: "(Marketing & Treasury, Collaboration, Team, Developers & Incentivising Team Building/Maintaining Project)",
    isdesc: true,
  },
  // {
  //   title: "Marketing & Treasury",
  //   value: "5%",
  //   isBlue: true,
  // },
  // {
  //   title: "Collaboration",
  //   desc: "(Exchanges & Market Makers)",
  //   value: "5%",
  //   isdesc: true,
  // },
  // {
  //   title: "KOLs",
  //   value: "5%",
  //   isBlue: true,
  // },
];

const RowView = ({
  title,
  value,
  isBlue,
  desc,
  isdesc,
}: {
  title: string;
  value: string;
  isBlue?: boolean;
  desc?: string;
  isdesc?: boolean;
}) => {
  return (
    <Column gap="0px">
      <Row
        width="94%"
        padding="5px 12px"
        justify="space-between"
        align="left"
        background={isBlue ? "rgba(82, 170, 255, 0.20)" : ""}
        borderRadius="10px"
      >
        <Column gap="2px">
          <Text
            color="#1F2024B2"
            fontSize="18px"
            fontWeight="700"
            lineHeight="26.66px"
            fontFamily="Space Mono"
          >
            {title}
          </Text>
          {isdesc && (
            <Row width="90%" justify="start" align="center">
              <Text
                color="#1F2024B2"
                fontSize="12px"
                fontWeight="700"
                lineHeight="17.77px"
                fontFamily="Space Mono"
                textAlign="left"
              >
                {desc}
              </Text>
            </Row>
          )}
        </Column>

        <Text
          color={"#1F2024"}
          fontSize={"18px"}
          fontWeight={"700"}
          lineHeight={"normal"}
          fontFamily="Space Mono"
        >
          {value}
        </Text>
      </Row>
    </Column>
  );
};

const Tokenomics = () => {
  const { isTablet, isMobile } = useMatchBreakPoints();

  return (
    <ToknomicsWrapper>
      <MainContainer>
        <Container>
          <CoinBg
            left="-147px"
            bottom="300px"
            backgroundImage={coin1}
            height="70px"
            width="70px"
          />
          <CoinBg
            backgroundImage={coin3}
            bottom={isTablet || isMobile ? "350px" : "-60px"}
            left={isTablet || isMobile ? "-76px" : "-70px"}
          />
          <CoinBg
            left={isTablet || isMobile ? "-20px" : "-30px"}
            top={isTablet || isMobile ? "-110px" : "-90px"}
            width="100px"
            height="100px"
            backgroundImage={coin2}
          />
          {!isTablet ? (
            <></>
          ) : (
            <Text
              fontSize={isMobile ? "24px" : "35px"}
              fontWeight={900}
              fontFamily="PPRightGrotesk"
              textAlign={"center"}
            >
              TOKENOMICS
            </Text>
          )}

          {!isTablet ? <></> : <UnderlineIcon3 width="60%" />}
          {!isTablet ? (
            <></>
          ) : (
            <Column gap="5px" align="center">
              <Text
                color={"#1F2024"}
                fontSize={"18px"}
                fontFamily={"PPRightGrotesk"}
                fontWeight={"900"}
                textAlign="left"
              >
                NO TAXES
              </Text>
              <UnderlineIcon3
                width={isMobile || isTablet ? "100px" : "60%"}
                color="#FFCA3C"
              />
              <Spacer size="sm" />
              <Text
                color={"#1F2024"}
                fontSize={"18px"}
                fontFamily={"PPRightGrotesk"}
                fontWeight={"900"}
                textAlign="left"
              >
                LIQUIDITY LOCKED
              </Text>
              <UnderlineIcon3
                width={isTablet ? "170px" : "55%"}
                color="#FE7649"
              />
            </Column>
          )}
          <Image
            src={avtar4}
            width={isTablet ? "260px" : "420px"}
            height={isTablet ? "260px" : "420px"}
            alt={"Avtar3"}
          />
          <RightColumn>
            {!isTablet && (
              <Text
                fontSize="35px"
                fontWeight={900}
                fontFamily="PPRightGrotesk"
                lineHeight={"normal"}
              >
                TOKENOMICS
              </Text>
            )}

            {!isTablet && <UnderlineIcon width="70%" />}
            <Spacer />
            {isTablet ? (
              <></>
            ) : (
              <Column gap="5px" justify="center">
                <Text
                  color={"#1F2024"}
                  fontSize={"18px"}
                  fontFamily={"PPRightGrotesk"}
                  fontWeight={"900"}
                  textAlign="left"
                >
                  NO TAXES
                </Text>
                <UnderlineIcon width="30%" color="#FFCA3C" />
                <Spacer size="sm" />
                <Text
                  color={"#1F2024"}
                  fontSize={"18px"}
                  fontFamily={"PPRightGrotesk"}
                  fontWeight={"900"}
                  textAlign="left"
                >
                  LIQUIDITY LOCKED
                </Text>
                <UnderlineIcon width="55%" color="#FE7649" />
              </Column>
            )}

            <Spacer />
            <Text
              color={"#fe994e"}
              fontSize={"20px"}
              fontFamily={"PPRightGrotesk"}
              fontWeight={"900"}
              textAlign="left"
            >
              TOTAL SUPPLY
            </Text>
            <Text
              color={"#52aaff"}
              fontSize={"60px"}
              fontFamily={"PPRightGrotesk"}
              fontWeight={"900"}
              lineHeight={"normal"}
              textAlign="left"
            >
              1 Bn
            </Text>
            <Spacer size="sm" />
            <Column width="100%" gap="2px">
              {TOKENOMICS_DATA.map((data) => {
                return (
                  <RowView
                    title={data.title}
                    value={data.value}
                    isBlue={data.isBlue}
                    desc={data.desc}
                    isdesc={data.isdesc}
                  />
                );
              })}
            </Column>
          </RightColumn>
          <CoinBg
            left="405px"
            bottom="-83px"
            backgroundImage={coinmiddle}
            height="80px"
            width="80px"
            filter="2px"
          />
          <CoinBg
            bottom="-95px"
            right="76px"
            backgroundImage={coinright3}
            height="105px"
            width="105px"
          />
          <CoinBg
            top={isMobile || isTablet ? "50px" : "12px"}
            right={isMobile || isTablet ? "-15px" : "-17px"}
            backgroundImage={coinright1}
            height={isMobile || isTablet ? "70px" : "140px"}
            width={isMobile || isTablet ? "70px" : "140px"}
          />
          <CoinBg
            right={isMobile || isTablet ? "-60px" : "-80px"}
            top={isMobile || isTablet ? "315px" : "240px"}
            backgroundImage={coinright2}
            height="90px"
            width="90px"
          />
        </Container>
      </MainContainer>
    </ToknomicsWrapper>
  );
};

export default Tokenomics;
