import { ThemeProvider } from "styled-components";
import { WagmiProvider } from "wagmi";
import ModalProvider from "./components/Modal/ModalContext";
import { customWagmiConfig } from "./utils/customWagmi";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

const selectedTheme = {
  name: "Light",
  colors: {
    bgBlue: "#52AAFF",
    bgCloudWhite: "#DDF9FD",
    bgOrange: "#FE7649",
    cardBorder: "#1F2024",
    text: "#1F2024",
    button: {
      text: "#1F2024",
      background: "#000000",
    },
    link: {
      text: "teal",
      opacity: 1,
    },
  },
  modal: {
    text: "#1F2024",
    background: "#DDF9FD",
    overlay: "rgba(31, 32, 36, 0.20)",
  },
  shadows: {
    warning: "",
    success: "",
    focus: "",
    boxShadow:
      "0px 11px 15px 0px rgba(5, 5, 5, 0.20), 0px 24px 38px 0px rgba(5, 5, 5, 0.14), 0px 4px 4px 0px rgba(5, 5, 5, 0.25)",
  },
  radii: {
    card: "28px",
  },
};

const queryClient = new QueryClient();
const Providers: React.FC<
  React.PropsWithChildren<{
    children: React.ReactNode;
  }>
> = ({ children }) => {
  return (
    <WagmiProvider config={customWagmiConfig}>
      <QueryClientProvider client={queryClient}>
        <ThemeProvider theme={selectedTheme}>
          <ModalProvider>{children}</ModalProvider>
        </ThemeProvider>
      </QueryClientProvider>
    </WagmiProvider>
  );
};

export default Providers;
