import React from "react";
import { Column, Container, Grid, Row } from "../../../components/Layout";
import { Text } from "../../../components/Text";
import Button from "../../../components/Button/MainButton";
import ArrowIcon from "../../../public/images/icons/arrow.svg";
import CopyIcon from "../../../public/images/icons/copy.svg";
import Spacer from "../../../components/Spacer";
import { AutoRow } from "../../../components/Layout/Row";
import useMatchBreakPoints from "../../../utils/useMatchBreakPoints";
import UnderlineIcon, {
  GibberishUnderline,
  SwapMaticUnderline,
  UnderlineIcon2,
  UnderlineIcon3,
} from "../../../components/Icons";
import { BUY_ON_URL, TOKEN_CONTRACT_ADDRESS } from "../../../config/constant";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { shortenAddress } from "../../../utils";
import SwapGuideCardList from "./swap-guide-card";

function HowToBuy() {
  const { isTablet, isMobile } = useMatchBreakPoints();

  return (
    <Row width="100%" justify="center" align="center">
      <Container width="100%" py="48px">
        <Column width="100%" justify="center" align="center">
          <Column>
            <Text
              fontSize="20px"
              textTransform="uppercase"
              fontWeight={700}
              lineHeight={"normal"}
              fontFamily="Space Mono"
            >
              STEP 2
            </Text>
            <Text
              fontSize="36px"
              textTransform="uppercase"
              fontWeight={900}
              lineHeight={"normal"}
              fontFamily="PPRightGrotesk"
            >
              SWAP MATIC FOR $GIB!
            </Text>
            <Column gap="0px">
              <SwapMaticUnderline
                width={isMobile ? "90%" : isTablet ? "40%" : "100%"}
              />
              <SwapMaticUnderline
                width={isMobile ? "90%" : isTablet ? "40%" : "100%"}
              />
            </Column>
          </Column>
          <Spacer />
          <Text
            fontSize="14px"
            fontWeight={400}
            lineHeight={"normal"}
            fontFamily="Space Mono"
          >
            Contract Address
          </Text>
          <Text
            fontSize="30px"
            fontWeight={700}
            lineHeight={"normal"}
            fontFamily="Space Mono"
          >
            {shortenAddress(TOKEN_CONTRACT_ADDRESS)}
          </Text>
          <Spacer />
          <AutoRow
            width="100%"
            justify="center"
            gap={isTablet ? "4px" : "10px"}
          >
            <a
              href={BUY_ON_URL}
              target="_blank"
              style={{ textDecoration: "none" }}
            >
              <Button
                isPrimary
                textProps={{ textTransform: "uppercase", bold: true }}
                endIcon={<img src={ArrowIcon} />}
              >
                BUY ON QUICKSWAP
              </Button>
            </a>
            <Button
              width={isTablet ? "289px" : "auto"}
              textProps={{ textTransform: "uppercase", bold: true }}
              endIcon={<img src={CopyIcon} />}
              onClick={() => {
                navigator.clipboard.writeText(TOKEN_CONTRACT_ADDRESS);
                toast("Token address copied");
              }}
            >
              COPY ADDRESS
            </Button>
          </AutoRow>

          <ToastContainer />
          <Spacer size="lg" />
          <Spacer size="lg" />
          <Spacer size="lg" />
          <SwapGuideCardList />
          <Spacer />
        </Column>
      </Container>
    </Row>
  );
}

export default HowToBuy;
