import styled from "styled-components";
import Card from "../../../components/Card/Card";
import { Column, Container, Flex, Grid, Row } from "../../../components/Layout";
import { Text } from "../../../components/Text";
import WalletImg from "./../../../public/images/how-to-buy/wallet.png";
import MaticCoinsImg from "./../../../public/images/how-to-buy/matic-coins.png";
import QuickswapImg from "./../../../public/images/how-to-buy/quickswap-desktop.png";
import SwapMaticGibImg from "./../../../public/images/how-to-buy/swap-matic-gib.png";

import useMatchBreakPoints from "../../../utils/useMatchBreakPoints";
import UnderlineIcon from "../../../components/Icons";

const StyledCard = styled(Card)<{ rotate?: number; bgColor: string }>`
  display: flex;
  width: 340px;
  max-width: 345px;
  height: 386px;
  transform: ${({ rotate }) => `rotate(${rotate}deg)`};
  justify-content: center;
  align-items: flex-start;
  align-self: stretch;
  background: ${({ bgColor }) => bgColor ?? ""};
  position: relative;
  overflow: visible;
  @media (max-width: 1200px) {
    transform: rotate(0deg);
    height: 365px;
    width: 345px;
  }
`;

const StyledImg = styled.img`
  border-radius: 16px;
  width: 272px;
  height: 176px;
  padding: 24px 24px 0px 24px;
  position: absolute;
  top: -70px;
  left: 0;
  z-index: 20;
  // background: url(<path-to-image>), lightgray 50% / cover no-repeat;
`;

const StyledCol = styled.div`
  display: flex;
  padding: 24px 24px 48px 24px;
  flex-direction: column;
  align-items: left;
  gap: 12px;
  align-self: stretch;
  padding-top: 176px;
  text-align: left;
`;

const GUIDE_DATA: {
  imgUrl: string;
  title: string;
  desc: string;
  rotate: number;
  bgColor: string;
  underlineWidth: string;
}[] = [
  {
    imgUrl: WalletImg,
    title: "CREATE A WALLET",
    desc: "Download Metamask or your wallet of choice from the App Store or Google Play for free. Desktop users, download Google Chrome extension by going to Metamask.io.",
    rotate: -2,
    bgColor: "#FFCA3C",
    underlineWidth: "80%",
  },
  {
    imgUrl: MaticCoinsImg,
    title: "GET SOME MATIC",
    desc: "Have MATIC in your wallet to switch to GIB. If you don't have any MATIC, you can buy directly on Metamask, transfer from another wallet, buy on another exchange and send it to your wallet, or use Simpleswap.",
    rotate: 2,
    bgColor: "#52AAFF",
    underlineWidth: "80%",
  },
];

function BuyGuideCard({
  imgUrl,
  title,
  desc,
  rotate,
  bgColor,
  underlineWidth,
}: {
  imgUrl: string;
  title: string;
  desc: string;
  rotate: number;
  bgColor: string;
  underlineWidth: string;
}) {
  const { isMobile, isTablet } = useMatchBreakPoints();
  return (
    <StyledCard rotate={rotate} bgColor={bgColor}>
      <StyledImg src={imgUrl} />
      <StyledCol>
        <Text
          fontSize="20px"
          textTransform="uppercase"
          fontWeight={900}
          textAlign="left"
          fontFamily="PPRightGrotesk"
        >
          {title}
        </Text>
        <UnderlineIcon width={isMobile || isTablet ? underlineWidth : "100%"} />
        <Text
          fontSize="14px"
          fontWeight={400}
          lineHeight={"normal"}
          textAlign="left"
          fontFamily="Space Mono"
        >
          {desc}
        </Text>
      </StyledCol>
    </StyledCard>
  );
}

function BuyGuideCardList() {
  const { isTablet, isMobile } = useMatchBreakPoints();

  return (
    <Flex width="100%" justifyContent="center" alignItems="center">
      <Grid
        gridTemplateColumns={isTablet ? "1fr" : "1fr 1fr"}
        gridRowGap="82px"
      >
        {GUIDE_DATA.map((data) => (
          <BuyGuideCard {...data} />
        ))}
      </Grid>
    </Flex>
  );
}

export default BuyGuideCardList;
