import React, { Children, ReactNode } from "react";
import styled from "styled-components";

const ActionContainer = styled.div`
  display: flex;
  width: 640px;
  padding-bottom: 3px;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-radius: 20px;
  background: var(--Charcoal, #1f2024);
  @media screen and (max-width: 1200px) {
  }
`;
const InnerContainer = styled.div`
  display: flex;
  padding: 12px;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  gap: 8px;
  align-self: stretch;
  border-radius: 20px;
  border: 2px solid var(--Charcoal, #1f2024);
  background: var(--Cloud-White, #ddf9fd);
  @media screen and (max-width: 1200px) {
  }
`;

function StyledCard({
  children,
}: {
  children: React.ReactElement | React.ReactElement[];
}) {
  return (
    <ActionContainer>
      <InnerContainer>{children}</InnerContainer>
    </ActionContainer>
  );
}

export default StyledCard;
