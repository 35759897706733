import styled from "styled-components";
import DownloadMemes from "../view/homepage/download-memes";
import Emissions from "../view/homepage/emissions-burn";
import Gibintro from "../view/homepage/gib-intro";
import Background from "../view/homepage/hero/Background";
import Middle from "../view/homepage/hero/Middle";
import HowToBuyMatic from "../view/homepage/get-matic";
import Tokenomics from "../view/homepage/tokenomics";
import WhoIsGib from "../view/homepage/who-is-gib";
import { useState } from "react";
import HowToSwap from "../view/homepage/swap-matic";
import HowToBuy from "../view/homepage/how-to-buy";
import Spacer from "../components/Spacer";

const HomeWrapper = styled.div`
  width: 100%;
  overflow: hidden;
`;
Homepage.propTypes = {};

function Homepage() {
  const [hoverText, setHoverText] = useState("");
  const [hoveredIndex, setHoveredIndex] = useState<number | null>(null);

  const handleSetHoverText = (text: string) => {
    setHoverText(text);
  };
  return (
    <HomeWrapper>
      <Middle
        setHoverText={handleSetHoverText}
        setHoveredIndex={setHoveredIndex}
      />
      <Background hoveredIndex={hoveredIndex} />
      <Gibintro />
      <Tokenomics />
      <Spacer size="lg" />
      {/* <Emissions /> */}
      <HowToBuy />
      <HowToBuyMatic />
      <HowToSwap />
      <DownloadMemes />
      <WhoIsGib />
    </HomeWrapper>
  );
}

export default Homepage;
