import styled from "styled-components";
import Card from "../../components/Card/Card";
import { Column, Container, Flex, Grid } from "../../components/Layout";
import { Text } from "../../components/Text";
import WalletImg from "./../../public/images/how-to-buy/wallet.png";
import MaticCoinsImg from "./../../public/images/how-to-buy/matic-coins.png";
import QuickswapImg from "./../../public/images/how-to-buy/quickswap-desktop.png";
import SwapMaticGibImg from "./../../public/images/how-to-buy/swap-matic-gib.png";
import meme1 from "../../public/images/memes/meme1.png";
import meme2 from "../../public/images/memes/meme2.png";
import gibburn from "../../public/images/avtar/burngib.png";

import useMatchBreakPoints from "../../utils/useMatchBreakPoints";
import UnderlineIcon from "../../components/Icons";
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-self: stretch;
  @media screen and (max-width: 768px) {
    padding: 4px 31px 10px 19px;
  }
`;
const BuyCardWrapper = styled.div<{ padding?: string }>`
  padding: ${(props) => props.padding};
`;

const StyledCard = styled.div<{
  rotate: number;
  leftPos?: string;
  bottomPos?: string;
}>`
  display: flex;
  max-width: 420px;
  flex-direction: column;
  justify-content: flex-end;
  transform: rotate(${(props) => props.rotate || 0}deg);
  align-items: center;
  left: ${(props) => props.leftPos || "unset"};
  bottom: ${(props) => props.bottomPos || "unset"};
  position: relative;
  @media screen and (max-width: 1200px) {
    transform: rotate(1deg);
  }
  @media screen and (max-width: 768px) {
    transform: rotate(1deg);
  }
`;

const StyledImg = styled.img`
  height: 240px;
  position: relative;
  left: -24px;
  bottom: -69px;
  @media screen and (max-width: 768px) {
    height:200px;
  }
}
`;
const DataCard = styled(Card)`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  width: 170px;
  left: 100px;
  top: 27px;
  transform: translateX(-50%);
  z-index: 1;
  background-color: #ffca3c;
  padding: 8px;
  @media screen and (max-width: 768px) {
    padding: 7px 5px;
    gap: 10px;
    left: 80px;
    top: 30px;
  }
`;
const StyledCol = styled.div`
  display: flex;
  padding: 48px 32px;
  justify-content: center;
  align-items: center;
  gap: 12px;
  align-self: stretch;
  border-radius: 64px;
  border: 3px solid #1f2024;
  flex-direction: column;
  background-color: ${(props) => props.color};
  box-shadow: 0px 11px 15px 0px rgba(5, 5, 5, 0.2),
    0px 24px 38px 0px rgba(5, 5, 5, 0.14), 0px 4px 4px 0px rgba(5, 5, 5, 0.25);
  z-index: 0;
  @media screen and (max-width: 768px) {
    gap: 40px;
    padding: 50px 24px;
    border-radius: 32px;
  }
`;
const StyledColMobile = styled.div`
  position: relative;
  display: flex;
  padding: 70px 130px;
  justify-content: center;
  align-items: center;
  gap: 12px;
  align-self: stretch;
  border-radius: 64px;
  border: 3px solid #1f2024;
  flex-direction: column;
  background-color: ${(props) => props.color};
  box-shadow: 0px 11px 15px 0px rgba(5, 5, 5, 0.2),
    0px 24px 38px 0px rgba(5, 5, 5, 0.14), 0px 4px 4px 0px rgba(5, 5, 5, 0.25);
  z-index: 0;
  @media screen and (max-width: 768px) {
    gap: 40px;
    padding: 74px 90px;
    border-radius: 32px;
  }
`;
const ROADMP_DATA: {
  imgUrl: string;
  title: string;
  desc: string;
  rotate: number;
  data: string;
  bgColor: string;
}[] = [
  {
    imgUrl: meme2,
    title: "Milestone Heading",
    desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.",
    rotate: -2,
    bgColor: "#FE994E",
    data: "$100,000",
  },
  {
    imgUrl: meme1,
    title: "Milestone Heading",
    desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.",
    rotate: 2,
    bgColor: "#52AAFF",
    data: "$500,000",
  },
  {
    imgUrl: gibburn,
    title: "Milestone Heading",
    desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.",
    rotate: -2,
    bgColor: "#FE7649",
    data: "$1,000,000",
  },
  {
    imgUrl: "",
    title: "",
    desc: "",
    rotate: 2,
    bgColor: "#FE994E",
    data: "$5,000,000",
  },
  {
    imgUrl: "",
    title: "",
    desc: "",
    rotate: -2,
    bgColor: "#52AAFF",
    data: "$10,000,000",
  },
  {
    imgUrl: "",
    title: "",
    desc: "",
    rotate: 2,
    data: "$100,000,000",
    bgColor: "#FE7649",
  },
];

function BuyGuideCard({
  imgUrl,
  title,
  desc,
  rotate,
  bgColor,
  data,
  leftPos,
  bottomPos,
}: {
  imgUrl: string;
  title: string;
  desc: string;
  rotate: number;
  bgColor: string;
  data: string;
  leftPos?: string;
  bottomPos?: string;
}) {
  const isInformation = !imgUrl && !title;
  const { isMobile } = useMatchBreakPoints();

  if (!imgUrl && !desc) {
    leftPos = isMobile ? "20px" : "50px";
    bottomPos = isMobile ? "224px" : "300px";
  }
  return (
    <Wrapper>
      <StyledCard rotate={rotate} leftPos={leftPos} bottomPos={bottomPos}>
        {imgUrl ? <StyledImg src={imgUrl} /> : ""}

        <DataCard>
          <Text
            fontSize={isMobile ? "16px" : "20px"}
            textTransform="uppercase"
            fontWeight={900}
            textAlign="center"
            lineHeight={"normal"}
          >
            {data}
            <Column padding="0px 25px">
              <UnderlineIcon width="85%" />
            </Column>
          </Text>
        </DataCard>
        {isInformation ? (
          <StyledColMobile color={bgColor}>
            <Text
              fontSize="96px"
              textTransform="uppercase"
              fontWeight={900}
              textAlign="center"
            >
              ???
            </Text>

            <Text
              fontSize="14px"
              fontWeight={400}
              lineHeight={"normal"}
              textAlign="center"
            ></Text>
          </StyledColMobile>
        ) : (
          <StyledCol color={bgColor}>
            <Text
              fontSize="24px"
              textTransform="uppercase"
              fontWeight={900}
              textAlign="center"
            >
              {title}
            </Text>

            <Text
              fontSize="14px"
              fontWeight={400}
              lineHeight={"normal"}
              textAlign="center"
            >
              {desc}
            </Text>
          </StyledCol>
        )}
      </StyledCard>
    </Wrapper>
  );
}
function RoadMapCardList() {
  const { isMobile } = useMatchBreakPoints();

  return (
    <Flex justifyContent="center" alignItems="center" position="absolute">
      <Column padding="24px 0px" align="center" justify="center" gap="10px">
        {ROADMP_DATA.map((data, index) => (
          <BuyCardWrapper
            padding={
              isMobile
                ? " "
                : index === 0
                ? "24px 0px "
                : index % 2 === 1
                ? "8px 0px 24px 240px"
                : "0px 240px 24px 0px"
            }
            key={index}
          >
            <BuyGuideCard
              leftPos={
                isMobile
                  ? index === 0
                    ? "22px"
                    : index % 2 === 1
                    ? "23px"
                    : "16px"
                  : index === 0
                  ? "22px"
                  : index % 2 === 1
                  ? "27px"
                  : "76px"
              }
              bottomPos={
                isMobile
                  ? index === 0
                    ? "-30px"
                    : index % 2 === 0
                    ? "211px"
                    : "92px"
                  : index === 0
                  ? "unset"
                  : index % 2 === 0
                  ? "276px"
                  : "140px"
              }
              {...data}
            />
          </BuyCardWrapper>
        ))}
      </Column>
    </Flex>
  );
}

export default RoadMapCardList;
