import { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import Button from "../../components/Button/MainButton";
import { Dropdown } from "../../components/Dropdown";
import UnderlineIcon from "../../components/Icons";
import { Input } from "../../components/Input";
import { Column, Row } from "../../components/Layout";
import { Text } from "../../components/Text";
import AvtarSad from "../../public/images/avtar/avtar-sad.png";
import AvtarSurprise from "../../public/images/avtar/avtar-surprise.png";
import AvtarWait from "../../public/images/avtar/avtar-wait.png";
import logo from "../../public/images/gib-logo.svg";
import ForwardIcon from "../../public/images/icons/arrow-right.svg";
import DownIcon from "../../public/images/icons/chevron-down.svg";
import { isAddress } from "../../utils";
import EligibleUser from "./eligible-user";
import StyledCard from "./styled-card";
import ClaimSuccess from "./claim-success";
import { useAccount } from "wagmi";
import { CHAIN_IDS } from "../../utils/customWagmi";
import { ChainId } from "../../utils/chainId";

const AirdropContainer = styled.div<{ bgColorByStatus: string }>`
  width: 100%;
  min-height: ${window && window.innerHeight - 60}px;
  position: relative;
  background-color: ${({ bgColorByStatus }) => bgColorByStatus ?? "#52AAFF"};
  z-index: 0;
`;

const TopSection = styled.div`
  display: flex;
  padding: 20px 24px 20px 24px;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  align-self: stretch;
  z-index: 0;
`;

const BottomSection = styled(Row)`
  width: 100%;
  height: 420px;
  position: absolute;
  justify-content: flex-end;
  align-items: flex-end;
  bottom: 0px;
  left: 0px;
  z-index: -1;
`;

export const LogoGibClaim = styled.img<{ isEligible: boolean }>`
  width: ${({ isEligible }) => (isEligible ? "120px" : "240px")};
  height: ${({ isEligible }) => (isEligible ? "120px" : "240px")};
  @media screen and (max-width: 1200px) {
    width: 100px;
    height: 100px;
  }
`;

const BottomIconStatus = styled.img`
  width: 420px;
  height: 420px;
  @media screen and (max-width: 1200px) {
    width: 240px;
    height: 240px;
  }
`;

const ActionSection = styled(Row)`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
  align-self: stretch;
  @media screen and (max-width: 1200px) {
  }
`;

const IconButton = styled(Button)`
  display: flex;
  width: 160px;
  height: 40px;
  padding: 0px 16px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  align-self: stretch;
  @media screen and (max-width: 1200px) {
  }
`;

const AirdropHeader = ({
  address,
  isEligible,
  amount,
}: {
  address: string | undefined;
  isEligible: boolean;
  amount: number | undefined;
}) => {
  const titlePrefixByStatus =
    address && amount
      ? isEligible && amount !== 0
        ? "You Have"
        : "You are"
      : "";
  const titleByStatus =
    address && amount
      ? isEligible && amount !== 0
        ? amount
        : "Noteligible!!!"
      : "Airdrop";
  const titleSuffixByStatus =
    address && amount
      ? isEligible && amount !== 0
        ? "CLAIMABLE!!!"
        : "HAHAHAHA"
      : "CHECK ELIGIBILITY";
  const descriptionByStatus =
    address && amount
      ? isEligible && amount !== 0
        ? "Connect your eligible wallet and a Polygon wallet that you’d like to claim your $GIB into"
        : "Enter another address below to check if you are eligible!"
      : "Enter your address below to check if you are eligible!";

  return (
    <Column>
      <Text fontSize="20px" fontWeight={900} lineHeight="normal" color="#fff">
        {titlePrefixByStatus}
      </Text>
      <Row gap="12px" justify="center">
        {(!address || amount === undefined) && (
          <LogoGibClaim src={logo} isEligible={isEligible} />
        )}
        <Column width="min-content" justify="flex-start" align="left">
          <Text
            color="#FFCA3C"
            fontSize="56px"
            fontWeight={900}
            textTransform="uppercase"
            lineHeight="normal"
            textAlign="left"
          >
            {titleByStatus}
          </Text>
          <UnderlineIcon color="#FFCA3C" width="140%" />
          <UnderlineIcon color="#FFCA3C" width="140%" />
        </Column>
        {isEligible && address && (
          <LogoGibClaim src={logo} isEligible={isEligible} />
        )}
      </Row>
      <Text fontSize="20px" fontWeight={900} lineHeight="normal" color="#fff">
        {titleSuffixByStatus}
      </Text>
      <Text fontSize="14px" py="8px" lineHeight="normal" color="#fff">
        {descriptionByStatus}
      </Text>
    </Column>
  );
};

function Airdrop({ setBgColor }: { setBgColor: (value: string) => void }) {
  const [isEligible, setIsEligible] = useState(false);
  const [address, setAddress] = useState(undefined);
  const [amount, setAmount] = useState<undefined | number>(undefined);
  const [isClaimedSuccess, setIsClaimedSuccess] = useState(false);
  const { address: account, isConnected } = useAccount();
  const [selectedChainId, setSelectedChainId] = useState(ChainId.ETHEREUM);

  const avtarByStatus = address
    ? isEligible
      ? AvtarSurprise
      : AvtarSad
    : AvtarWait;

  const handleInput = useCallback((event: any) => {
    const input = event.target.value;
    setAddress(input);
  }, []);

  const onForwardBtnClick = () => {
    setIsEligible(true);
    setAmount(10);
  };

  useEffect(() => {
    if (!isEligible && !address && amount === 0) {
      setBgColor("#FE7649");
    } else {
      setBgColor("#52AAFF");
    }
  }, [isEligible, address]);

  const onClaimClick = () => {
    try {
      setIsClaimedSuccess(true);
    } catch (e) {
      console.debug("Claim ERROR:", e);
    }
  };

  if (isClaimedSuccess) {
    return (
      <ClaimSuccess address={account ?? "0x"} amount={amount ?? 0} tx="" />
    );
  }

  return (
    <AirdropContainer
      bgColorByStatus={
        !isEligible && !address && amount === 0 ? "#FE7649" : "#52AAFF"
      }
    >
      <TopSection>
        <AirdropHeader
          address={address}
          isEligible={isEligible}
          amount={amount}
        />
      </TopSection>
      <BottomSection>
        <BottomIconStatus src={avtarByStatus} />
      </BottomSection>
      <ActionSection>
        {isEligible && address ? (
          <EligibleUser onClaimClick={onClaimClick} />
        ) : (
          <StyledCard>
            <Input
              id="address-input"
              placeholder="Enter wallet address or ENS..."
              scale="md"
              value={address}
              autoComplete="off"
              onChange={handleInput}
              isWarning={!isAddress(address)}
            />
            <Row>
              <Dropdown
                target={
                  <Row gap="10px">
                    <Text
                      fontSize="16px"
                      fontWeight={900}
                      fontFamily="PP Right Grotesk"
                    >
                      {ChainId[selectedChainId]}
                    </Text>
                    <img src={DownIcon} />
                  </Row>
                }
              >
                {CHAIN_IDS.map((value) => (
                  <Button
                    width="100%"
                    onClick={() => setSelectedChainId(value)}
                    scale="sm"
                  >
                    {ChainId[value]}
                  </Button>
                ))}
              </Dropdown>
              <IconButton
                isPrimary={true}
                disabled={!address}
                onClick={onForwardBtnClick}
              >
                <img src={ForwardIcon} />
              </IconButton>
            </Row>
          </StyledCard>
        )}
      </ActionSection>
    </AirdropContainer>
  );
}

export default Airdrop;
