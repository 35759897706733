import styled from "styled-components";
import LogoGIB from "../../public/images/logo.png";
import IconNavMenuClose from "../../public/images/icons/close.svg";
import { NavBarProps, NavItemStyled, NavLink, SocialView } from "./index";
import { Row } from "../../components/Layout";

const MobileNavWraper = styled.div`
  display: flex;
  min-width: 343px;
  height: 10000px;
  padding: 10px;
  margin: 4px;
  flex-direction: column;
  align-items: flex-start;
  background: rgba(31, 32, 36, 0.2);
  backdrop-filter: blur(5px);
`;

const MobileNavContainer = styled.div`
  display: flex;
  padding: 12px 24px 24px 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  border-radius: 28px;
  border: 2px solid var(--Charcoal, #1f2024);
  background: var(--Blue, #52aaff);
`;

const MobileNavHeader = styled.div`
  width: 100%;
  display: flex;
  height: 56px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
`;

const MobileNavContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
`;

const MobileNav: React.FC<NavBarProps> = ({
  items,
  setOpenMobileNav,
  isTransparentBg,
}) => {
  return (
    <MobileNavWraper>
      <MobileNavContainer>
        <MobileNavHeader>
          <img src={LogoGIB} alt="Logo" width="48px" height="48px" />
          <img
            src={IconNavMenuClose}
            alt="Logo"
            width="28px"
            height="28px"
            onClick={() => setOpenMobileNav && setOpenMobileNav(false)}
          />
        </MobileNavHeader>
        <MobileNavContent>
          {items &&
            items.map((item, index) => (
              <NavItemStyled key={index}>
                <NavLink href={item.link} target={item.target}>
                  {item.title}
                </NavLink>
              </NavItemStyled>
            ))}
        </MobileNavContent>
        <Row width="100%" justify="center">
          <SocialView />
        </Row>
      </MobileNavContainer>
    </MobileNavWraper>
  );
};
export default MobileNav;
