import { ChainId } from "./chainId";

export const PUBLIC_NODES = {
  [ChainId.ETHEREUM]: [
    "https://ethereum.publicnode.com",
    "https://eth.llamarpc.com",
    "https://cloudflare-eth.com",
  ].filter(Boolean),
  [ChainId.POLYGON]: ["	https://polygon-rpc.com/"].filter(Boolean),
} satisfies Record<ChainId, readonly string[]>;
