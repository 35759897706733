import React from "react";
import CloudeBottom from "../../public/images/decoration/bg-cloude-bottom.png";
import AvtarBottom from "../../public/images/avtar/avtar2.png";
import styled from "styled-components";
import { Text } from "../../components/Text";

const FooterWrapper = styled.div`
  width: 100%;
  align-self: stretch;
  background: var(--Blue, #52aaff);
  position: relative;
`;

const CloudeImage = styled.img`
  width: 100%;
  height: 360px;
  flex-shrink: 0;
  z-index: 2;
  top: -4px;
  position: absolute;
  @media (max-width: 1200px) {
    object-position: 25% 75%;
    height: 160px;
  }
`;

const AvtarImageWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 400px;
  position: absolute;
  overflow: hidden;
`;

const AvtarImage = styled.div`
  flex-shrink: 0;
  width: 834px;
  height: 1800px;
  padding-right: 380px;
  flex-shrink: 0;
  top: -200px;
  background: url(${AvtarBottom});
  background-repeat: no-repeat;
  @media (max-width: 1200px) {
    padding-right: 280px;
    background-position: 95% 25%;
    background-size: 600px;
    max-width: 200px;
    height: 1800px;
  }
`;

const FooterTextWrappper = styled.div`
  display: flex;
  padding: 48px;
  justify-content: center;
  align-items: flex-start;
  gap: 12px;
  padding-top: 340px;
  align-self: stretch;
`;

function Footer() {
  return (
    <FooterWrapper>
      <AvtarImageWrapper>
        <AvtarImage />
      </AvtarImageWrapper>
      <CloudeImage src={CloudeBottom} />
      <FooterTextWrappper>
        <Text
          fontSize="12px"
          lineHeight="normal"
          textAlign="center"
          fontFamily="Space Mono"
        >
          $GIB was inspired by Wojak Paradise and has no relation to his
          artwork.
        </Text>
      </FooterTextWrappper>
    </FooterWrapper>
  );
}

export default Footer;
