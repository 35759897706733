import { title } from "process";
import { useCallback, useEffect, useMemo, useState } from "react";
import { InjectedModalProps, Modal, ModalProps } from "./Modal";
import useModal from "./Modal/useModal";
import { WalletOptions } from "./WalletOptions";
import { useAccount } from "wagmi";
import Button from "./Button/MainButton";

type props = {
  title: string;
  setCurrentWallet: any;
  currentWallet: any;
  customOnDismiss?: () => void;
};

const ConfirmationModal: React.FC<
  React.PropsWithChildren<InjectedModalProps & props & ModalProps>
> = ({
  title,
  setCurrentWallet,
  currentWallet,
  customOnDismiss,
  onDismiss,
  ...props
}): any => {
  const handleDismiss = useCallback(() => {
    if (customOnDismiss) {
      customOnDismiss();
    }
    onDismiss?.();
  }, [customOnDismiss, onDismiss]);

  return (
    <Modal title={title} onDismiss={handleDismiss} {...props}>
      <WalletOptions
        setCurrentWallet={setCurrentWallet}
        currentWallet={currentWallet}
      />
    </Modal>
  );
};

function WalletConnectButton(): React.ReactElement {
  const [currentWallet, setCurrentWallet] = useState<any>(undefined);
  const {
    address,
    isConnected,
    isConnecting,
    isReconnecting,
    isDisconnected,
    connector,
  } = useAccount();

  const customOnDismiss = () => {
    // Action on close
  };

  const setDataValue = (value: any) => {
    console.log("set value valled", value);
    setCurrentWallet(value);
  };

  const [walletConnectModal, onDismissCallback] = useModal(
    <ConfirmationModal
      title={
        isConnecting && currentWallet ? currentWallet?.name : "Connect Wallet"
      }
      currentWallet={currentWallet}
      setCurrentWallet={setDataValue}
      customOnDismiss={customOnDismiss}
    />,
    false,
    true
  );

  useMemo(() => {
    if (isConnected) {
      onDismissCallback();
    }
  }, [
    address,
    isConnected,
    isConnecting,
    isReconnecting,
    isDisconnected,
    connector,
    onDismissCallback,
  ]);

  return (
    <Button scale="sm" isPrimary onClick={walletConnectModal}>
      Connect
    </Button>
  );
}

export default WalletConnectButton;
