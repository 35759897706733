import React, { Dispatch, SetStateAction, useState } from "react";
import styled from "styled-components";
import Button from "../../../components/Button/MainButton";
import { UnderlineIcon2 } from "../../../components/Icons";
import { Column, Row } from "../../../components/Layout";
import { BOT_TELEGRAM_URL, BUY_ON_URL } from "../../../config/constant";
import logo from "../../../public/images/gib-logo.svg";
import useMatchBreakPoints from "../../../utils/useMatchBreakPoints";

interface MiddleProps {
  setHoverText: (text: string) => void;
  setHoveredIndex: Dispatch<SetStateAction<number | null>>;
}
const ButtonWrapper = styled.div`
  padding: 30px 0px 30px;
  @media screen and (max-width: 768px) {
    padding: 10px 0px 30px;
  }
`;
const Logo = styled.img`
  width: 480px;
  height: 400px;

  @media screen and (max-width: 768px) {
    display: flex;
    height: 212px;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #52aaff;
  @media screen and (max-width: 768px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1 0 0;
  }
`;

const Text = styled.div`
  color: #fff;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 35.54px;
  white-space: nowrap;
  font-family: "Space Mono";
  @media screen and (max-width: 768px) {
    font-size: 18px;
    line-height: 26.66px;
  }
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: start;
  gap: 17px;
  flex-wrap: wrap;
  width: 780px;
  @media screen and (max-width: 768px) {
    width: 90%;
    padding: 0px 0px 30px 13px;
  }
`;

const gibTexts = [
  "$GIB",
  "LAUGHTER.",
  "$GIB",
  "CULTURE.",
  "$GIB",
  "MEMES.",
  "NEVER EVER $GIB YOUR",
  "SEED PHRASE",
  "OR",
  "MOM'S OF.",
];

const UnderlineElement = ({
  text,
  onMouseEnter,
  onMouseLeave,
}: {
  text: string;
  onMouseEnter: () => void;
  onMouseLeave: () => void;
}) => {
  return (
    <Column
      width="min-content"
      key={text}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      gap="0px"
    >
      <Text>{text}</Text>
      <UnderlineIcon2 color="#FFCA3C" width="100%" />
    </Column>
  );
};

const Middle: React.FC<MiddleProps> = ({ setHoverText, setHoveredIndex }) => {
  const [hoveredIndex, setHoverIndex] = useState<number | null>(null);
  const handleHover = (text: string, index: number) => {
    setHoveredIndex(index);
  };

  const handleHoverLeave = () => {
    setHoverText("");
    setHoveredIndex(null);
  };
  const { isMobile } = useMatchBreakPoints();

  return (
    <Container>
      <Logo src={logo} alt="logo" />
      <TextWrapper>
        {gibTexts.map((text, index) => {
          return (
            <>
              {text.includes(gibTexts[1]) ||
              text.includes(gibTexts[3]) ||
              text.includes(gibTexts[5]) ||
              text.includes(gibTexts[7]) ||
              text.includes(gibTexts[9]) ? (
                <UnderlineElement
                  text={text}
                  onMouseEnter={() => handleHover(text, index)}
                  onMouseLeave={handleHoverLeave}
                />
              ) : (
                <Text
                  color="#fff"
                  key={index}
                  onMouseEnter={() => handleHover(text, index)}
                  onMouseLeave={handleHoverLeave}
                >
                  {text}
                </Text>
              )}
            </>
          );
        })}
      </TextWrapper>
      <ButtonWrapper>
        <Row justify="center" gap="10px">
          <a
            href={BUY_ON_URL}
            target="_blank"
            style={{ textDecoration: "none" }}
          >
            <Button
              width={"120px"}
              isPrimary
              textProps={{
                textTransform: "uppercase",
                bold: true,
                fontSize: "20px",
                fontWeight: "900",
                color: "#1F2024",
                textAlign: "center",
              }}
            >
              BUY
            </Button>
          </a>
          <a
            href={BOT_TELEGRAM_URL}
            target="_blank"
            style={{ textDecoration: "none" }}
          >
            <Button
              width={"140px"}
              isPrimary
              textProps={{
                bold: true,
                fontSize: "20px",
                fontWeight: "900",
                color: "#1F2024",
                textAlign: "center",
              }}
            >
              Gibbot
            </Button>
          </a>
        </Row>
      </ButtonWrapper>
    </Container>
  );
};

export default Middle;
