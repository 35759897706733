import { useAccount, useDisconnect } from "wagmi";
import { IconButton } from "../../components/Button/IconButton";
import Button from "../../components/Button/MainButton";
import Divider from "../../components/Divider";
import { Column, Row } from "../../components/Layout";
import { Modal } from "../../components/Modal";
import useModal from "../../components/Modal/useModal";
import { Text } from "../../components/Text";
import { WalletOptions } from "../../components/WalletOptions";
import disConnnectIcon from "../../public/images/icons/disconnect.svg";
import { shortenAddress } from "../../utils";
import StyledCard from "./styled-card";
import { useEffect } from "react";
import WalletConnectButton from "../../components/CustomWalletConnectButton";

function EligibleUser({ onClaimClick }: { onClaimClick: () => void }) {
  const {
    address,
    isConnected,
    isConnecting,
    isReconnecting,
    isDisconnected,
    connector,
  } = useAccount();

  const { disconnect } = useDisconnect();

  const [walletConnectModal, onDismissCallback] = useModal(
    <Modal title={"Connect Wallet"}>
      <WalletOptions />
    </Modal>
  );

  useEffect(() => {
    if (isConnected) {
      onDismissCallback();
    }
  }, [isConnected, address]);

  return (
    <Column width="640px" gap="8px" justify="center" align="center">
      {[{ title: "ETHEREUM" }, { title: "POLYGON" }].map((element) => {
        return (
          <StyledCard>
            {isConnected ? (
              <Column gap="8px" align="start">
                <Text fontSize="16px" fontWeight={900} p="8px">
                  ELIGIBLE ADDRESS
                </Text>
                <Row
                  width="fit-content"
                  justify="flex-start"
                  gap="10px"
                  padding="0px 8px"
                >
                  {/* <img src={disConnnectIcon} /> */}
                  <Text fontSize="12px" fontWeight={400} lineHeight="normal">
                    {address && shortenAddress(address)}
                  </Text>
                </Row>
                <Divider />
              </Column>
            ) : (
              <></>
            )}
            <Row>
              <Text fontSize="16px" fontWeight={900} px="8px">
                {element.title}
              </Text>
              {isConnected ? (
                <IconButton onClick={() => disconnect()}>
                  <img src={disConnnectIcon} style={{ padding: "0px 8px" }} />
                </IconButton>
              ) : (
                <WalletConnectButton />
              )}
            </Row>
          </StyledCard>
        );
      })}
      <Button
        width="640px"
        scale="md"
        isPrimary
        onClick={onClaimClick}
        disabled={!address}
      >
        Claim
      </Button>
    </Column>
  );
}

export default EligibleUser;
