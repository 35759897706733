import React from "react";
import styled from "styled-components";
import { Column, Row } from "../../components/Layout";
import Image from "../../components/Image";
import GibBlacklogo from "../../public/images/gib-blacklogo.svg";
import useMatchBreakPoints from "../../utils/useMatchBreakPoints";
import { Text } from "../../components/Text";
import RoadmapLine from "../../public/images/icons/line.svg";
import RoadMapCardList from "./roadmap-card";
import UnderlineIcon from "../../components/Icons";
import MobileRoadmapLine from "../../public/images/icons/mobileline.svg";
const LineWrapper = styled.div`
  display: flex;
  width: 640px;
  height: 2055px;
  max-width: 640px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
const MilestoneWrapper = styled.div`
  display: flex;
  padding: 0px 24px 150px 24px;
  flex-direction: column;
  align-items: center;
  align-self: stretch;

  @media screen and (max-width: 768px) {
    padding: 24px 24px 24px 24px;
  }
  @media screen and (max-width: 1200px) {
  }
`;
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: stretch;
  padding: 24px;
`;
export const TextWrapper = styled.div`
  display: flex;
  padding: 24px 24px 0px 24px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  @media screen and (max-width: 768px) {
    padding-top: 24px;
  }
`;
export default function Roadmap() {
  const { isMobile, isTablet } = useMatchBreakPoints();
  return (
    <Wrapper>
      <TextWrapper>
        <Row
          gap="8px"
          justify="center"
          align="center"
          padding={isMobile || isTablet ? "0px" : "24px 24px 0px 24px"}
        >
          <Image
            src={GibBlacklogo}
            width={isMobile ? "100px" : "160px"}
            height={isMobile ? "100px" : "160px"}
            alt={"Giblogo"}
          />
          <Text
            fontSize={isMobile ? "30px" : "36px"}
            fontWeight={"900"}
            lineHeight={"normal"}
          >
            ROADMAP
            <Column>
              <UnderlineIcon width="60%" color="#FFCA3C" />
              <UnderlineIcon width="60%" color="#FFCA3C" />
            </Column>
          </Text>
        </Row>
      </TextWrapper>
      <MilestoneWrapper>
        <LineWrapper>
          <Image
            width={isMobile ? "320px" : "640px"}
            height={isMobile ? "2004px" : "2100px"}
            position="absolute"
            src={isMobile ? MobileRoadmapLine : RoadmapLine}
            alt="Roadmapline logo"
          />
        </LineWrapper>

        <RoadMapCardList />
      </MilestoneWrapper>
    </Wrapper>
  );
}
