import React from "react";

const UnderlineIcon = ({ color, width }: any) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width ?? "385"}
      height="6"
      viewBox="0 0 385 6"
      fill="none"
    >
      <path
        d="M219.275 5.34853C212.12 5.34853 204.939 5.10441 197.817 5.40441C186.621 5.87647 175.472 5.21912 164.302 5.26029C155.934 5.28971 147.566 5.23235 139.2 5.25294C127.275 5.28235 115.349 5.33235 103.423 5.40588C97.7562 5.44118 92.0914 5.60882 86.4266 5.61176C80.9167 5.61471 75.4067 5.42206 69.8992 5.42794C62.7865 5.43529 55.6738 5.56618 48.5612 5.61471C46.4515 5.62941 44.3395 5.48529 42.2323 5.525C35.5138 5.65 28.7977 5.87353 22.0793 5.96618C16.9636 6.03676 11.8432 5.98382 6.72521 5.95294C4.25889 5.93823 2.25486 5.16176 0.440903 4.21029C0.084213 4.02353 -0.0307725 3.55588 0.00677375 3.22941C0.0232002 3.09265 0.713113 2.89706 1.11439 2.88088C3.68866 2.77206 6.26996 2.74118 8.84188 2.61765C20.6525 2.05 32.4632 1.57353 44.3255 1.63529C49.6711 1.66323 55.0191 1.29118 60.3718 1.225C68.3832 1.12647 76.3994 1.15441 84.4132 1.1C93.9124 1.03676 103.412 0.939705 112.911 0.866176C116.663 0.836764 120.42 0.870588 124.172 0.798529C129.952 0.686764 135.727 0.45147 141.509 0.372058C149.054 0.267646 156.601 0.25147 164.145 0.222058C169.071 0.202941 173.999 0.263235 178.924 0.224999C184.239 0.183823 189.552 0.00882297 194.867 0.00147002C201.708 -0.00882409 208.551 0.0911759 215.391 0.142647C219.847 0.17647 224.308 0.157352 228.762 0.254411C235.283 0.397058 241.8 0.645588 248.319 0.805882C253.123 0.923529 257.931 0.980882 262.739 1.04853C263.676 1.06176 264.612 0.989705 265.548 0.963235C267.099 0.920588 268.362 1.32794 269.422 1.99412C270.401 2.60882 270.089 3.20147 268.803 3.46912C265.039 4.25147 261.158 4.37647 257.236 4.47059C251.928 4.59853 246.632 4.94265 241.324 5.05294C234.917 5.18676 228.502 5.17206 222.091 5.22059C221.152 5.22794 220.213 5.22059 219.275 5.22059C219.275 5.26323 219.275 5.30588 219.275 5.34853Z"
        fill={color ?? "#1F2024"}
      />
    </svg>
  );
};

export default UnderlineIcon;

export const UnderlineIcon2 = ({ color, width }: any) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width ?? "163"}
      height="4"
      viewBox="0 0 163 4"
      fill="none"
    >
      <path
        d="M132.065 3.56569C127.772 3.56569 123.464 3.40294 119.19 3.60294C112.473 3.91765 105.783 3.47941 99.0815 3.50686C94.0606 3.52647 89.0397 3.48824 84.0202 3.50196C76.8649 3.52157 69.7095 3.5549 62.5541 3.60392C59.1538 3.62745 55.7549 3.73922 52.3561 3.74118C49.0501 3.74314 45.7442 3.61471 42.4396 3.61863C38.172 3.62353 33.9044 3.71078 29.6368 3.74314C28.371 3.75294 27.1038 3.65686 25.8395 3.68333C21.8084 3.76667 17.7788 3.91569 13.7477 3.97745C10.6783 4.02451 7.60606 3.98922 4.53525 3.96863C3.05546 3.95882 1.85304 3.44118 0.764664 2.80686C0.55065 2.68235 0.481659 2.37059 0.504186 2.15294C0.514042 2.06176 0.92799 1.93137 1.16876 1.92059C2.71332 1.84804 4.2621 1.82745 5.80525 1.7451C12.8916 1.36667 19.978 1.04902 27.0954 1.0902C30.3028 1.10882 33.5116 0.860784 36.7232 0.816666C41.5301 0.75098 46.3397 0.769608 51.148 0.733333C56.8475 0.691176 62.5471 0.62647 68.2466 0.577451C70.498 0.557843 72.7521 0.580392 75.0035 0.532353C78.4714 0.457843 81.9364 0.30098 85.4057 0.248039C89.9324 0.178431 94.4605 0.167647 98.9871 0.148039C101.942 0.135294 104.899 0.17549 107.855 0.15C111.044 0.122549 114.231 0.00588198 117.42 0.000980016C121.525 -0.00588273 125.63 0.0607839 129.735 0.0950977C132.408 0.117647 135.085 0.104902 137.757 0.169607C141.67 0.264706 145.58 0.430392 149.492 0.537255C152.374 0.615686 155.259 0.653921 158.144 0.699019C158.705 0.707843 159.267 0.659804 159.829 0.642157C160.76 0.613725 161.517 0.885294 162.154 1.32941C162.741 1.73922 162.553 2.13431 161.782 2.31275C159.523 2.83431 157.195 2.91765 154.842 2.98039C151.657 3.06569 148.479 3.2951 145.294 3.36863C141.451 3.45784 137.601 3.44804 133.755 3.48039C133.191 3.48529 132.628 3.48039 132.065 3.48039C132.065 3.50882 132.065 3.53725 132.065 3.56569Z"
        fill={color ?? "#FFCA3C"}
      />
    </svg>
  );
};

export const UnderlineIcon3 = ({ color, width }: any) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width ?? "390"}
      height="5"
      viewBox="0 0 390 5"
      fill="none"
    >
      <path
        d="M316.418 4.48561C306.11 4.48561 295.764 4.32286 285.503 4.52286C269.373 4.83757 253.31 4.39933 237.217 4.42678C225.161 4.44639 213.104 4.40816 201.052 4.42188C183.87 4.44149 166.688 4.47482 149.506 4.52384C141.341 4.54737 133.18 4.65914 125.018 4.6611C117.08 4.66306 109.142 4.53463 101.207 4.53855C90.9592 4.54345 80.7117 4.63071 70.4642 4.66306C67.4247 4.67286 64.3819 4.57678 61.3459 4.60326C51.6663 4.68659 41.9902 4.83561 32.3107 4.89737C24.9403 4.94443 17.5632 4.90914 10.1894 4.88855C6.63608 4.87875 3.74879 4.3611 1.13535 3.72678C0.621451 3.60227 0.455787 3.29051 0.509881 3.07286C0.533548 2.98169 1.52753 2.85129 2.10567 2.84051C5.81452 2.76796 9.53351 2.74737 13.239 2.66502C30.2551 2.28659 47.2712 1.96894 64.3616 2.01012C72.0633 2.02875 79.7684 1.78071 87.4802 1.73659C99.0226 1.6709 110.572 1.68953 122.118 1.65325C135.803 1.6111 149.489 1.54639 163.175 1.49737C168.581 1.47776 173.994 1.50031 179.4 1.45227C187.727 1.37776 196.048 1.2209 204.378 1.16796C215.248 1.09835 226.121 1.08757 236.991 1.06796C244.087 1.05522 251.187 1.09541 258.283 1.06992C265.941 1.04247 273.596 0.925804 281.253 0.920902C291.109 0.914039 300.967 0.980706 310.823 1.01502C317.243 1.03757 323.67 1.02482 330.087 1.08953C339.483 1.18463 348.871 1.35031 358.264 1.45718C365.184 1.53561 372.112 1.57384 379.039 1.61894C380.388 1.62776 381.737 1.57973 383.086 1.56208C385.321 1.53365 387.14 1.80522 388.668 2.24933C390.078 2.65914 389.628 3.05424 387.775 3.23267C382.352 3.75424 376.76 3.83757 371.111 3.90031C363.463 3.98561 355.833 4.21502 348.185 4.28855C338.955 4.37776 329.712 4.36796 320.475 4.40031C319.123 4.40522 317.77 4.40031 316.418 4.40031C316.418 4.42875 316.418 4.45718 316.418 4.48561Z"
        fill={color ?? "#1F2024"}
      />
    </svg>
  );
};

export const SwapMaticUnderline = ({ color, width }: any) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width ?? "422"}
      height="5"
      viewBox="0 0 422 5"
      fill="none"
    >
      <path
        d="M342.718 4.30543C331.535 4.30543 320.312 4.14269 309.181 4.34269C291.682 4.65739 274.257 4.21916 256.798 4.24661C243.719 4.26622 230.64 4.22798 217.565 4.24171C198.926 4.26131 180.286 4.29465 161.647 4.34367C152.789 4.3672 143.936 4.47896 135.082 4.48092C126.47 4.48288 117.858 4.35445 109.25 4.35837C98.1331 4.36328 87.0162 4.45053 75.8994 4.48288C72.6021 4.49269 69.3012 4.39661 66.0076 4.42308C55.5069 4.50641 45.0099 4.65543 34.5092 4.7172C26.5136 4.76426 18.5107 4.72896 10.5114 4.70837C6.65661 4.69857 3.52438 4.18092 0.689236 3.54661C0.131744 3.4221 -0.0479742 3.11033 0.0107092 2.89269C0.0363832 2.80151 1.11469 2.67112 1.74187 2.66033C5.76535 2.58779 9.79984 2.5672 13.8197 2.48484C32.2793 2.10641 50.7389 1.78877 69.2792 1.82994C77.6342 1.84857 85.9929 1.60053 94.359 1.55641C106.881 1.49073 119.409 1.50935 131.935 1.47308C146.782 1.43092 161.629 1.36622 176.475 1.3172C182.34 1.29759 188.212 1.32014 194.077 1.2721C203.11 1.19759 212.137 1.04073 221.174 0.987785C232.966 0.918177 244.761 0.907393 256.553 0.887785C264.251 0.87504 271.953 0.915236 279.652 0.889746C287.959 0.862295 296.263 0.745628 304.57 0.740726C315.262 0.733863 325.957 0.80053 336.648 0.834844C343.613 0.857393 350.586 0.844648 357.547 0.909354C367.739 1.00445 377.925 1.17014 388.114 1.277C395.621 1.35543 403.137 1.39367 410.652 1.43877C412.115 1.44759 413.579 1.39955 415.042 1.3819C417.466 1.35347 419.44 1.62504 421.097 2.06916C422.627 2.47896 422.139 2.87406 420.129 3.05249C414.246 3.57406 408.18 3.65739 402.051 3.72014C393.755 3.80543 385.477 4.03484 377.18 4.10837C367.167 4.19759 357.14 4.18779 347.12 4.22014C345.652 4.22504 344.185 4.22014 342.718 4.22014C342.718 4.24857 342.718 4.277 342.718 4.30543Z"
        fill={color ?? "#FE994E"}
      />
    </svg>
  );
};

export const GibberishUnderline = ({ color, width }: any) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width ?? "300"}
      height="5"
      viewBox="0 0 300 5"
      fill="none"
    >
      <path
        d="M58.1576 3.56569C66.293 3.56569 74.4577 3.40294 82.5558 3.60294C95.2858 3.91765 107.963 3.47941 120.663 3.50686C130.178 3.52647 139.693 3.48824 149.205 3.50196C162.765 3.52157 176.325 3.5549 189.885 3.60392C196.329 3.62745 202.77 3.73922 209.211 3.74118C215.476 3.74314 221.741 3.61471 228.003 3.61863C236.09 3.62353 244.178 3.71078 252.265 3.74314C254.664 3.75294 257.065 3.65686 259.461 3.68333C267.1 3.76667 274.737 3.91569 282.376 3.97745C288.193 4.02451 294.015 3.98922 299.834 3.96863C302.638 3.95882 304.917 3.44118 306.98 2.80686C307.385 2.68235 307.516 2.37059 307.473 2.15294C307.455 2.06176 306.67 1.93137 306.214 1.92059C303.287 1.84804 300.352 1.82745 297.427 1.7451C283.998 1.36667 270.569 1.04902 257.081 1.0902C251.003 1.10882 244.922 0.860784 238.836 0.816666C229.727 0.75098 220.612 0.769608 211.5 0.733333C200.699 0.691176 189.898 0.62647 179.097 0.577451C174.831 0.557843 170.559 0.580392 166.292 0.532353C159.721 0.457843 153.154 0.30098 146.58 0.248039C138.001 0.178431 129.42 0.167647 120.842 0.148039C115.241 0.135294 109.638 0.17549 104.038 0.15C97.9941 0.122549 91.9532 0.00588198 85.9097 0.000980016C78.1319 -0.00588273 70.3513 0.0607839 62.5735 0.0950977C57.5065 0.117647 52.4342 0.104902 47.37 0.169607C39.955 0.264706 32.5453 0.430392 25.133 0.537255C19.6712 0.615686 14.204 0.653921 8.73679 0.699019C7.67215 0.707843 6.60754 0.659804 5.54294 0.642157C3.77924 0.613725 2.34375 0.885294 1.1377 1.32941C0.0250549 1.73922 0.379944 2.13431 1.8421 2.31275C6.12195 2.83431 10.5352 2.91765 14.9938 2.98039C21.0293 3.06569 27.0515 3.2951 33.0869 3.36863C40.3712 3.45784 47.6661 3.44804 54.9557 3.48039C56.023 3.48529 57.0903 3.48039 58.1576 3.48039C58.1576 3.50882 58.1576 3.53725 58.1576 3.56569Z"
        fill={color ?? "#1F2024"}
      />
    </svg>
  );
};
