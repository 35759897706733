import styled from "styled-components";
import avtar from "../../../public/images/avtar/avtar1.png";
import CloudeTop from "../../../public/images/decoration/bg-cloude-top.png";
import GibLaughter from "../../../public/images/landingpage/giblaughter.png";
import GibCulture from "../../../public/images/landingpage/gibculture.png";
import GibMems from "../../../public/images/landingpage/gibmem.png";
import GibMoms from "../../../public/images/landingpage/momsof.png";
import GibSeed from "../../../public/images/landingpage/seedphrase.png";
interface BackgroundProps {
  hoveredIndex: number | null;
}
const ImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  flex: 1 0 0;
  align-self: stretch;
  display: flex;
  justify-content: space-between;
  width: 100%;
  background-color: #52aaff;
  position: relative;
`;
const ImageWrapper = styled.div`
  max-width: 512px;
  height: 430px;
  @media screen and (max-width: 768px) {
    height: 300px;
  }
  @media screen and (max-width: 1200px) {
    height: 300px;
  }
`;
const FirstImage = styled.img`
  width: 510px;
  height: 430px;
  @media screen and (max-width: 768px) {
    width: 360px;
    height: 300px;
  }
  @media screen and (max-width: 1200px) {
    width: 360px;
    height: 300px;
  }
`;

const SecondImage = styled.img`
  width: 100%;
  height: 210px;
  position: absolute;
  bottom: 0px;
  @media screen and (max-width: 800px) {
    width: 800px;
    height: 210px;
  }
`;

const Background: React.FC<BackgroundProps> = ({ hoveredIndex }) => {
  let firstImageSrc = avtar;
  if (hoveredIndex === 1) {
    firstImageSrc = GibLaughter;
  }
  if (hoveredIndex === 3) {
    firstImageSrc = GibCulture;
  }
  if (hoveredIndex === 5) {
    firstImageSrc = GibMems;
  }
  if (hoveredIndex === 7) {
    firstImageSrc = GibSeed;
  }
  if (hoveredIndex === 9) {
    firstImageSrc = GibMoms;
  }

  return (
    <>
      <ImageContainer>
        <ImageWrapper>
          <FirstImage src={firstImageSrc} alt="Avtar Image" />
        </ImageWrapper>
        <SecondImage src={CloudeTop} alt="Cloud Image" />
      </ImageContainer>
    </>
  );
};

export default Background;
