import styled from "styled-components";
import Airdrop from "../view/airdrop";

const AirdropWrapper = styled.div`
  width: 100%;
  overflow: hidden;
`;

function AirdropPage({ setBgColor }: { setBgColor: (value: string) => void }) {
  return (
    <AirdropWrapper>
      <Airdrop setBgColor={setBgColor} />
    </AirdropWrapper>
  );
}

export default AirdropPage;
