import styled from "styled-components";
import Card from "../../../components/Card/Card";
import { Column, Container, Flex, Grid, Row } from "../../../components/Layout";
import { Text } from "../../../components/Text";
import WalletImg from "./../../../public/images/how-to-buy/wallet.png";
import MaticCoinsImg from "./../../../public/images/how-to-buy/matic-coins.png";
import QuickswapImg from "./../../../public/images/how-to-buy/quickswap-desktop.png";
import SwapMaticGibImg from "./../../../public/images/how-to-buy/swap-matic-gib.png";

import useMatchBreakPoints from "../../../utils/useMatchBreakPoints";
import UnderlineIcon, { UnderlineIcon3 } from "../../../components/Icons";

const StyledCard = styled(Card)<{ rotate?: number; bgColor: string }>`
  display: flex;
  width: 340px;
  max-width: 345px;
  height: 432px;
  transform: ${({ rotate }) => `rotate(${rotate}deg)`};
  justify-content: center;
  align-items: flex-start;
  align-self: stretch;
  background: ${({ bgColor }) => bgColor ?? ""};
  position: relative;
  overflow: visible;
  @media (max-width: 1200px) {
    transform: rotate(0deg);
    height: 365px;
    width: 345px;
  }
`;

const StyledImg = styled.img`
  border-radius: 16px;
  width: 283.8px;
  height: 172px;
  padding: 24px 24px 0px 24px;
  position: absolute;
  top: -70px;
  left: 0;
  z-index: 20;
  // background: url(<path-to-image>), lightgray 50% / cover no-repeat;
`;

const StyledCol = styled.div`
  display: flex;
  padding: 24px 24px 48px 24px;
  flex-direction: column;
  align-items: left;
  gap: 12px;
  align-self: stretch;
  padding-top: 176px;
  justify-content: center;
  text-align: center;
`;

const GUIDE_DATA: {
  imgUrl: string;
  title: string;
  desc: string;
  rotate: number;
  bgColor: string;
  underlineWidth: string;
}[] = [
  {
    imgUrl: QuickswapImg,
    title: "GO TO QUICKSWAP",
    desc: "Connect to Quickswap. Go to quickswap.exchange in Google Chrome browser or on the browser inside your Metamask app. Connect your wallet, paste the GIB token address into Quick,swap, select GIB and confirm. When Metamask prompts you for a wallet signature, sign.",
    rotate: -2,
    bgColor: "#52AAFF",
    underlineWidth: "62%",
  },
  {
    imgUrl: SwapMaticGibImg,
    title: "SWAP MATIC FOR GIB",
    desc: "Switch MATIC for GIB. We have ZERO taxes so you don't need to worry about buying with a specific slippage, although you may need to use slippage during times of market volatility.",
    rotate: 2,
    bgColor: "#FFCA3C",
    underlineWidth: "75%",
  },
];

function SwapGuideCard({
  imgUrl,
  title,
  desc,
  rotate,
  bgColor,
  underlineWidth,
}: {
  imgUrl: string;
  title: string;
  desc: string;
  rotate: number;
  bgColor: string;
  underlineWidth: string;
}) {
  const { isMobile, isTablet } = useMatchBreakPoints();
  return (
    <StyledCard rotate={rotate} bgColor={bgColor}>
      <StyledImg src={imgUrl} />
      <StyledCol>
        <Text
          fontSize="20px"
          textTransform="uppercase"
          fontWeight={900}
          textAlign="left"
        >
          {title}
        </Text>
        <UnderlineIcon3 width={isMobile || isTablet ? underlineWidth : "90%"} />
        <Text
          fontSize="14px"
          fontWeight={400}
          lineHeight={"normal"}
          textAlign="left"
          fontFamily="Space Mono"
        >
          {desc}
        </Text>
      </StyledCol>
    </StyledCard>
  );
}

function SwapGuideCardList() {
  const { isTablet, isMobile } = useMatchBreakPoints();

  return (
    <Flex
      width="100%"
      justifyContent="center"
      alignItems="center"
      style={{ gap: "10px" }}
    >
      <Grid
        gridTemplateColumns={isTablet ? "1fr" : "1fr 1fr"}
        gridRowGap="70px"
      >
        {GUIDE_DATA.map((data) => (
          <SwapGuideCard {...data} />
        ))}
      </Grid>
    </Flex>
  );
}

export default SwapGuideCardList;
