import { getAddress } from "@ethersproject/address";
import { Chain, mainnet, polygon } from "viem/chains";
import { wagmiSupportedChains } from "./customWagmi";

export function refreshPage() {
  window && window.location.reload();
  //  navigate("refresh");
  //  setTimeout(() => {
  //    navigate("/");
  //  }, 100);
}

export function isAddress(value: any): string | false {
  try {
    return getAddress(value);
  } catch {
    return false;
  }
}

export function shortenAddress(address: `0x${string}`, chars = 4): string {
  const parsed = isAddress(address);
  if (!parsed) {
    throw Error(`Invalid 'address' parameter '${address}'.`);
  }
  return `${parsed.substring(0, chars + 2)}...${parsed.substring(42 - chars)}`;
}

export function getBlockExploreLink(
  data: string | number,
  type: "transaction" | "token" | "address" | "block",
  chainId?: number
): string {
  const chain = wagmiSupportedChains.find((c) => c.id === chainId);
  if (!chain) return polygon.blockExplorers?.default.url ?? "";
  switch (type) {
    case "transaction": {
      return `${chain.blockExplorers?.traceMove.url}transaction/${data}`;
    }
    case "block": {
      return `${chain.blockExplorers?.traceMove.url}block/${data}`;
    }
    case "token": {
      return `${chain.blockExplorers?.traceMove.url}coin/${data}`;
    }
    default: {
      return `${chain.blockExplorers?.traceMove.url}search/${data}`;
    }
  }
}
