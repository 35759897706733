import memoize from "lodash/memoize";
import { Chain, mainnet, polygon } from "viem/chains";
import { injected, metaMask, walletConnect } from "wagmi/connectors";
import { PUBLIC_NODES } from "./node";
import { createConfig, http, type Config } from "@wagmi/core";
import { type CreateConfigParameters } from "wagmi";

export const wagmiSupportedChains: [Chain, ...Chain[]] = [mainnet, polygon];
const projectId = "e542ff314e26ff34de2d4fba98db70bb";

export const injectedConnector = injected();

export const walletConnectConnector = walletConnect({
  showQrModal: true,
  projectId,
});

export const walletConnectNoQrCodeConnector = walletConnect({
  showQrModal: false,
  projectId,
});

export const metaMaskConnector = metaMask();

export const customWagmiConfig: Config = createConfig({
  chains: wagmiSupportedChains,
  transports: {
    [mainnet.id]: http(PUBLIC_NODES[mainnet.id][0]),
    [polygon.id]: http(PUBLIC_NODES[polygon.id][0]),
  },
  connectors: [metaMaskConnector, injectedConnector, walletConnectConnector],
});

export const CHAIN_IDS = wagmiSupportedChains.map((c) => c.id);

export const isChainSupported = memoize((chainId: number) =>
  (CHAIN_IDS as number[]).includes(chainId)
);
export const isChainTestnet = memoize((chainId: number) => {
  const found = wagmiSupportedChains.find((c) => c.id === chainId);
  return found ? "testnet" in found : false;
});

declare module "wagmi" {
  interface Register {
    config: typeof customWagmiConfig;
  }
}
