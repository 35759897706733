import React from "react";
import claimSuccessGif from "../../public/gif/claim-success.gif";
import styled from "styled-components";
import Card from "../../components/Card/Card";
import UnderlineIcon from "../../components/Icons";
import { Row, Column } from "../../components/Layout";
import Spacer from "../../components/Spacer";
import useMatchBreakPoints from "../../utils/useMatchBreakPoints";
import { Text } from "../../components/Text";
import { LogoGibClaim } from "./index";
import logo from "../../public/images/gib-logo.svg";
import { getBlockExploreLink, refreshPage, shortenAddress } from "../../utils";
import Button from "../../components/Button/MainButton";
import { Link, useNavigate } from "react-router-dom";

const ClaimSuccessWrraper = styled.div`
  width: 100%;
  min-height: calc(100vh - 80px);
  background-color: ${({ theme }) => theme.colors.bgBlue};
  @media (max-width: 1200px) {
    padding: 0px;
    min-height: calc(100vh - 50px);
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  min-height: calc(100vh - 260px);
  padding: 48px 48px 48px 96px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
  flex: 1 0 0;
  align-self: stretch;
  @media (max-width: 1200px) {
    min-height: calc(100vh - 460px);
    padding: 24px;
    align-self: stretch;
  }
`;

const ImgWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1 0 0;
  align-self: stretch;
  @media (max-width: 1200px) {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    gap: 10px;
    flex: 1 0 0;
  }
`;

const StyledImg = styled.img`
  width: 850px;
  flex: 1 0 0;
  position: fixed;
  right: -34.951px;
  bottom: 0px;
  @media (max-width: 1200px) {
    width: 393px;
    position: relative;
    right: 0px;
    bottom: 0px;
  }
`;

const AutoRow = styled(Row)`
  @media (max-width: 1200px) {
    flex-direction: column;
  }
`;

function ClaimSuccess({
  address,
  amount,
  tx,
}: {
  address: `0x${string}`;
  amount: number;
  tx: string;
}) {
  const { isTablet } = useMatchBreakPoints();
  const navigate = useNavigate();

  return (
    <ClaimSuccessWrraper>
      <Row width="100%" justify="center" p="0px" m="0px">
        <AutoRow width="100%" height="100%" justify="center" align="center">
          <ContentWrapper>
            <Column>
              <Text
                fontSize="20px"
                fontWeight={900}
                lineHeight="normal"
                color="#fff"
              >
                YOU HAVE CLAIMED
              </Text>
              <Row gap="12px" justify="center">
                <Column width="min-content" justify="flex-start" align="left">
                  <Text
                    color="#FFCA3C"
                    fontSize="56px"
                    fontWeight={900}
                    textTransform="uppercase"
                    lineHeight="normal"
                    textAlign="left"
                  >
                    {amount}
                  </Text>
                  <UnderlineIcon color="#FFCA3C" width="140%" />
                  <UnderlineIcon color="#FFCA3C" width="140%" />
                </Column>
                <LogoGibClaim src={logo} isEligible={true} />
              </Row>
            </Column>

            <Text fontSize="14px" py="8px" lineHeight="normal" color="#fff">
              Your GIB has been claimed into your Polygon wallet.
            </Text>
            <Text fontSize="30px" fontWeight={700} pb="16px" color="#fff">
              {shortenAddress(address)}
            </Text>
            <AutoRow
              width="100%"
              justify="center"
              gap={isTablet ? "4px" : "10px"}
            >
              <Button
                width={isTablet ? "100%" : "auto"}
                isPrimary
                textProps={{ textTransform: "uppercase", bold: true }}
                onClick={refreshPage}
              >
                CLAIM ANOTHER WALLET
              </Button>
              <Link
                to={getBlockExploreLink("", "transaction")}
                target="_blank"
                style={{
                  textDecoration: "none",
                  width: isTablet ? "100%" : "auto",
                }}
              >
                <Button
                  width={isTablet ? "100%" : "auto"}
                  textProps={{ textTransform: "uppercase", bold: true }}
                  onClick={() => undefined}
                >
                  VIEW TRANSACTION
                </Button>
              </Link>
            </AutoRow>
          </ContentWrapper>

          <ImgWrapper>
            <StyledImg src={claimSuccessGif} />
          </ImgWrapper>
        </AutoRow>
      </Row>
    </ClaimSuccessWrraper>
  );
}

export default ClaimSuccess;
