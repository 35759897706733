import React from "react";
import { Column, Container, Grid, Row } from "../../../components/Layout";
import { Text } from "../../../components/Text";
import Button from "../../../components/Button/MainButton";
import ArrowIcon from "../../../public/images/icons/arrow.svg";
import CopyIcon from "../../../public/images/icons/copy.svg";
import Spacer from "../../../components/Spacer";
import BuyGuideCardList from "./buy-guide-card";
import { AutoRow } from "../../../components/Layout/Row";
import useMatchBreakPoints from "../../../utils/useMatchBreakPoints";
import UnderlineIcon, { UnderlineIcon3 } from "../../../components/Icons";
import { BUY_ON_URL, TOKEN_CONTRACT_ADDRESS } from "../../../config/constant";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { shortenAddress } from "../../../utils";

function HowToBuy() {
  const { isTablet, isMobile } = useMatchBreakPoints();

  return (
    <Row width="100%" justify="center" align="center">
      <Container width="100%" py="48px">
        <Column width="100%" justify="center" align="center">
          <Column width="100%" justify="center" align="center">
            <Text
              fontSize={isMobile ? "28px" : "35px"}
              textTransform="uppercase"
              fontWeight={900}
              lineHeight={"normal"}
              fontFamily="PPRightGrotesk"
            >
              How to buy $GIB
            </Text>
            <UnderlineIcon3 width={isMobile ? "300px" : "400px"} />
          </Column>
          <Spacer />
          <Column>
            <Text
              fontSize={isMobile ? "16px" : "20px"}
              textTransform="uppercase"
              fontWeight={700}
              lineHeight={"normal"}
              fontFamily="Space Mono"
            >
              STEP 1
            </Text>
            <Text
              fontSize={isMobile ? "24px" : "36px"}
              textTransform="uppercase"
              fontWeight={900}
              lineHeight={isMobile ? "27.42px" : "41.13px"}
              fontFamily="PPRightGrotesk"
            >
              GET SOME MATIC
            </Text>
            <Column gap="0px">
              <UnderlineIcon3
                width={isMobile || isTablet ? "250px" : "400px"}
                color="#FE994E"
              />
              <UnderlineIcon3
                width={isMobile || isTablet ? "250px" : "400px"}
                color="#FE994E"
              />
            </Column>
          </Column>
          <Column width="321px" padding="8px 0px">
            <Text
              fontSize={isMobile ? "14px !important" : "16px"}
              fontWeight={400}
              lineHeight="23.7px"
              fontFamily="Space Mono"
              textAlign="center"
            >
              If you already have MATIC, skip to Step 2.
            </Text>
          </Column>

          <ToastContainer />
          <Spacer size="lg" />
          <Spacer size="lg" />
          <BuyGuideCardList />
          <Spacer />
        </Column>
      </Container>
    </Row>
  );
}

export default HowToBuy;
