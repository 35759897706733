import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import Roadmap from "../view/roadmap";

const RoadmapWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 24px;

  @media screen and (max-width: 768px) {
    gap: 24px;
    padding: 48px;
  }
`;

RoadmapPage.propTypes = {};

function RoadmapPage() {
  return (
    <RoadmapWrapper>
      <Roadmap />
    </RoadmapWrapper>
  );
}

export default RoadmapPage;
