import * as React from "react";
import { Connector, useAccount, useConnect } from "wagmi";
import MetamaskIcon from "../public/images/wallets/metamask.png";
import Button from "./Button/MainButton";
import { Column, Row } from "./Layout";
import { Text } from "./Text";
// import PhantomIcon from "../public/images/wallets/phantom.png";
import WalletConnectIcon from "../public/images/wallets/walletconnect.png";

const isMetamaskInstalled = () => {
  if (typeof window === "undefined") {
    return false;
  }

  if (window.ethereum?.isMetaMask) {
    return true;
  }

  if (
    window.ethereum?.providers?.some((p: { isMetaMask: any }) => p.isMetaMask)
  ) {
    return true;
  }

  return false;
};

export enum ConnectorNames {
  MetaMask = "metaMaskSDK",
  // Injected = "injected",
  WalletConnect = "walletConnect",
}

const walletsConfig = [
  {
    id: "metamask",
    title: "Metamask",
    icon: MetamaskIcon,
    connectorId: ConnectorNames.MetaMask,
    get installed() {
      return isMetamaskInstalled();
    },
  },
  {
    id: "walletconnect",
    title: "WalletConnect",
    icon: WalletConnectIcon,
    connectorId: ConnectorNames.WalletConnect,
  },
  // {
  //   id: "injected",
  //   title: "Injected",
  //   icon: `../public/wallets/walletconnect.png`,
  //   connectorId: ConnectorNames.Injected,
  //   installed: typeof window !== "undefined" && Boolean(window.ethereum),
  // },
];

function WalletOption({
  connector,
  onClick,
}: {
  connector: Connector;
  onClick: () => void;
}) {
  console.log("connector", connector);
  const [ready, setReady] = React.useState(false);
  const walletC = walletsConfig.find(
    (value) => value.connectorId === connector.id
  );

  React.useEffect(() => {
    (async () => {
      const provider = await connector.getProvider();
      setReady(!!provider);
    })();
  }, [connector]);

  return (
    <Button width="100%" scale="sm" disabled={!ready} onClick={onClick}>
      <Row width="100%" justify="space-between">
        <Text fontSize="16px" fontWeight={900}>
          {walletC?.title}
        </Text>
        <img src={walletC?.icon} width="24px" height="24px" />
      </Row>
    </Button>
  );
}

export function WalletOptions({
  currentWallet,
  setCurrentWallet,
}: any): React.ReactElement {
  const { connectors, connect } = useConnect();
  const { address, isConnected, isConnecting, isDisconnected } = useAccount();

  if (isConnected) {
    return (
      <Column width="100%" gap="12px" justify="center" align="center">
        <img src={currentWallet?.icon} />
        <Column maxWidth="320px" gap="12px" justify="center" align="center">
          <Text fontSize="16px" fontWeight={900} lineHeight="normal">
            CONNECTED
          </Text>
        </Column>
      </Column>
    );
  }

  if (isConnecting) {
    return (
      <Column width="100%" gap="12px" justify="center" align="center">
        <img src={currentWallet?.icon} />
        <Column maxWidth="320px" gap="12px" justify="center" align="center">
          <Text fontSize="16px" fontWeight={900} lineHeight="normal">
            REQUESTING CONNECTION
          </Text>
          <Text fontSize="14px" fontWeight={400} lineHeight="normal">
            Open the Metamask browser extension to connect your wallet.
          </Text>
        </Column>
        <Button scale="sm" width="100%">
          TRY AGAIN
        </Button>
      </Column>
    );
  }

  // if (error) {
  //   return (
  //     <Column width="100%" gap="12px" justify="center" align="center">
  //       <img src={currentWallet?.icon} />
  //       <Column maxWidth="320px" gap="12px" justify="center" align="center">
  //         <Text fontSize="16px" fontWeight={900} lineHeight="normal">
  //           FAILED
  //         </Text>
  //         <Text fontSize="14px" fontWeight={400} lineHeight="normal">
  //           Brief description of why the transaction failed.
  //         </Text>
  //       </Column>
  //       <Button>TRY AGAIN</Button>
  //     </Column>
  //   );
  // }

  return (
    <Column width="100%" gap="12px">
      {connectors
        .filter((value) =>
          Object.values(ConnectorNames).find((e) => e === value.id)
        )
        .map((connector) => (
          <WalletOption
            key={connector.uid}
            connector={connector}
            onClick={() => {
              const walletC = walletsConfig.find(
                (value) => value.connectorId === connector.id
              );
              setCurrentWallet(walletC);
              connect({ connector });
            }}
          />
        ))}
    </Column>
  );
}
