import React from "react";
import styled from "styled-components";
import UnderlineIcon from "../../../components/Icons";
import Title from "../../../components/Title/Title";
import avtar2 from "../../../public/images/avtar/avtar23.png";
import useMatchBreakPoints from "../../../utils/useMatchBreakPoints";

interface CardProps {
  title: string;
  description: string;
  imageUrl: string;
}
const Wrapper = styled.div`
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;
  padding: 48px 24px;
  padding: @media screen and (max-width: 768px) {
    padding: 31px 43px 0px 38px;
  }
  @media screen and (max-width: 1200px) {
    padding: 32px 32px 16px 32px;
  }
`;
const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  flex: 1 0 0;
  align-self: stretch;
  @media screen and (max-width: 375px) {
    display: flex;
    padding: 41px 32px 0px 32px;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
  }
  @media screen and (max-width: 768px) {
    display: flex;
    padding: 41px 32px 0px 32px;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
  }
  @media screen and (max-width: 1200px) {
    padding: 27px 0px 0px 8px;
    gap: 0px;
  }
`;
const CardContainer = styled.div`
  display: flex;
  width: 964px;
  height: 420px;
  flex-direction: row;
  transform: rotate(0.5deg);
  padding: 0px 70px;
  justify-content: center;
  gap: 48px;
  border-radius: 32px;
  border: 3px solid #1f2024;
  background: #ffca3c;
  box-shadow: 0px 11px 15px 0px rgba(5, 5, 5, 0.2),
    0px 24px 38px 0px rgba(5, 5, 5, 0.14), 0px 4px 4px 0px rgba(5, 5, 5, 0.25);
  @media screen and (max-width: 768px) {
    width: 430px;
    padding: 26px 14px;
    flex-direction: column;
  }
  @media screen and (max-width: 375px) {
    padding: 0px 10px;
  }
  @media screen and (max-width: 1200px) {
    padding: 0px 10px;
    gap: 20px;
  }
  @media screen and (max-width: 500px) {
    padding: 0px 8px;
  }
`;

const Image = styled.img`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  align-self: stretch;
  @media screen and (max-width: 768px) {
    height: 364.807px;
    width: 329px;
    flex: 1 0 0;
    padding: 0px;
  }
  @media screen and (max-width: 375px) {
    width: 288px;
  }
`;

const Description = styled.div`
  color: #1f2024;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 20px;
  font-family: "Space Mono";
`;

const StyledCard: React.FC<CardProps> = ({ title, description, imageUrl }) => {
  const { isMobile, isTablet } = useMatchBreakPoints();
  return (
    <Wrapper>
      <CardContainer>
        <TitleWrapper>
          <Title title={"HOO S GIB?"} />
          <UnderlineIcon width={isMobile || isTablet ? "70%" : "100%"} />
          <Description>{description}</Description>
        </TitleWrapper>
        <Image src={imageUrl} alt="GIB's Image" />
      </CardContainer>
    </Wrapper>
  );
};

const Gibintro = () => {
  return (
    <StyledCard
      title=""
      description="GIB likes 2 mak memes abot faekinternet money, evintuff GIB miteno mak rent dizmonth."
      imageUrl={avtar2}
    />
  );
};

export default Gibintro;
