import React, { useEffect, useState } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import styled from "styled-components";
import Providers from "./Providers";
import "./global.css";
import { GlobalStyles } from "./globalStyle";
import AirdropPage from "./pages/airdrop";
import GibberishTranslatePage from "./pages/gibberishgenerator";
import Homepage from "./pages/homepage";
import NotFoundPage from "./pages/not-found";
import RoadmapPage from "./pages/roadmap";
import Footer from "./view/footer";
import NavBar from "./view/navbar";
import SocialFooter from "./view/social-footer";

const AppWrapper = styled.div`
  width: 100%;
  overflow: hidden;
`;

const App: React.FC = () => {
  let location = useLocation();

  const hideTopNav = location.pathname === "";
  const hideSocialFooter = location.pathname === "/airdrop";
  const hideFooter = location.pathname === "/airdrop";

  const [bgColor, setBgColor] = useState("#52aaff");

  useEffect(() => {
    const dynamicBgColor =
      location.pathname === "/roadmap" ? "#DDF9FD" : "#52aaff";
    setBgColor(dynamicBgColor);
  }, [location]);

  return (
    <Providers>
      <GlobalStyles />
      <AppWrapper>
        {!hideTopNav && <NavBar bgColor={bgColor} />}
        <Routes>
          <Route path="/" element={<Homepage />} />
          <Route path="/roadmap" element={<RoadmapPage />} />
          <Route
            path="/airdrop"
            element={<AirdropPage setBgColor={setBgColor} />}
          />
          {/* <Route
            path="/gibberish-generator"
            element={<GibberishTranslatePage />}
          /> */}
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
        {!hideSocialFooter && <SocialFooter />}
        {!hideFooter && <Footer />}
      </AppWrapper>
    </Providers>
  );
};

export default App;
