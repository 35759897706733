import React, { ReactNode, useState } from "react";
import styled from "styled-components";
import LogoGib from "../../public/images/logo.png";
import twitter from "../../public/images/socials/twitter-icon.svg";
import telegram from "../../public/images/socials/telegram-icon.svg";
import IconNavMenu from "../../public/images/icons/menu.svg";
import useMatchBreakPoints from "../../utils/useMatchBreakPoints";
import MobileNav from "./mobile-nav";
import { SOCIAL_INFO } from "../../config/constant";
import { Link, useLocation } from "react-router-dom";
import { Text } from "../../components/Text";

const navItems = [
  { title: "HOO S GIB?", link: "#" },
  { title: "TOKENOMICS", link: "#" },
  { title: "HOW TO BUY", link: "#" },
  { title: "SoQuest", link: "https://soquest.xyz/space/GIB", target: "_blank" },
  { title: "GIBBOT", link: "https://t.me/sideGibBot", target: "_blank" },
  { title: "STAKING", link: "https://stake.gibmoreinfo.com", target: "_blank" },

  // { title: "GIBBERISH GENERATOR", link: "/gibberish-generator" },
];

interface NavItem {
  title: string;
  link: string;
  target?: string;
}

export interface NavBarProps {
  items?: NavItem[];
  setOpenMobileNav?: (value: boolean) => void;
  isTransparentBg?: boolean;
  bgColor?: string;
}

const NavContainer = styled.nav<{ bgColor?: string }>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px 24px;
  align-items: center;
  align-self: stretch;
  background-color: ${({ bgColor }) => bgColor ?? "transparent"};
  position: relative;
`;

const Logo = styled.img`
  width: 64px;
  height: 64px;
  @media (max-width: 1200px) {
    width: 48px;
    height: 48px;
  }
`;

const NavList = styled.ul`
  list-style: none;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
  gap: 24px;
`;

export const NavItemStyled = styled.li`
  display: flex;
`;

export const NavLink = styled.a<{
  isTransparentBg?: boolean;
  isOpen?: boolean;
}>`
  text-decoration: none;
  color: ${({ isTransparentBg }) => (isTransparentBg ? "#1F2024" : "#fff")};
  font-size: 20px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  // font-family: PPRightGrotesk, sans-serif;
  target: _blank;
  position: relative;

  &::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: #ffca3c;
    transform: scaleX(0);
    transform-origin: bottom right;
    transition: transform 0.3s ease-out;
  }

  ${({ isOpen }) =>
    isOpen &&
    `
  margin-top: 2px;
  text-decoration: underline;
  text-decoration-color: #ffca3c;
`}

  &:hover::before {
    transform: scaleX(1);
    transform-origin: bottom left;
  }

  &:hover {
    margin-top: 2px;
    text-decoration: none;
  }
`;

const SocialIcons = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 54px;
`;

const SocialIcon = styled.img<{ isTransparentBg: boolean }>`
  height: 25px;
  margin-left: 10px;
  color: ${({ isTransparentBg }) =>
    isTransparentBg ? "#fff" : "#1F2024"} !important;
`;

const MobileNavPositionWrapper = styled.div`
  width: 100%;
  position: absolute;
  z-index: 10;
  top: 0;
  right: 0;
`;

export const SocialView = () => {
  return (
    <SocialIcons>
      <a href={SOCIAL_INFO.twitter} target="_blank">
        <SocialIcon src={twitter} alt="Twitter" isTransparentBg />
      </a>
      <a href={SOCIAL_INFO.telegram} target="_blank">
        <SocialIcon src={telegram} alt="Telegram" isTransparentBg />
      </a>
    </SocialIcons>
  );
};

const NavBar: React.FC<NavBarProps> = ({ bgColor }) => {
  const { isTablet } = useMatchBreakPoints();
  const [openMobileNav, setOpenMobileNav] = useState(false);
  let location = useLocation();

  console.log("location", location);

  const isTransparentBg = location && location.pathname === "/roadmap";

  return (
    <NavContainer bgColor={bgColor}>
      <Link to="/">
        <Logo src={LogoGib} alt="Logo" />
      </Link>
      {!isTablet && (
        <NavList>
          {navItems.map((item, index) => (
            <NavItemStyled key={index}>
              <NavLink
                href={item.link}
                isTransparentBg={isTransparentBg}
                isOpen={location && location.pathname === item.link}
                target={item.target}
              >
                {item.title}
              </NavLink>
            </NavItemStyled>
          ))}
        </NavList>
      )}
      {isTablet ? (
        <img src={IconNavMenu} onClick={() => setOpenMobileNav(true)} />
      ) : (
        <SocialView />
      )}
      {openMobileNav ? (
        <MobileNavPositionWrapper>
          <MobileNav
            items={navItems}
            setOpenMobileNav={setOpenMobileNav}
            isTransparentBg={isTransparentBg}
          />
        </MobileNavPositionWrapper>
      ) : (
        <></>
      )}
    </NavContainer>
  );
};

export default NavBar;
