import styled from "styled-components";
import Card from "../../components/Card/Card";
import { Column, Container } from "../../components/Layout";
import Row from "../../components/Layout/Row";
import { BUY_ON_URL, SOCIAL_INFO } from "../../config/constant";
import useMatchBreakPoints from "../../utils/useMatchBreakPoints";
import LogoGIB from "../../public/images/logo.png";

const StyledCard = styled(Card)`
  width: 1024px;
  transform: rotate(0.25deg);
  padding: 12px;
  background-color: #52aaff;
  @media (max-width: 1200px) {
    width: 90%;
    height: 100%;
  }
`;

const ImgWrapper = styled.div`
  width: 100%;
  display: flex;
  padding: 10px 0px 10px 48px;
  justify-content: center;
  align-items: center;
  flex: 1 0 0;
  @media (max-width: 1200px) {
    padding: 0px;
  }
`;

const StyledImg = styled.img`
  width: 212px;
  height: 212px;
  @media (max-width: 1200px) {
    width: 160px;
    height: 160px;
  }
`;

const AutoRow = styled(Row)`
  gap: 48px;
  justify-content: center;
  align-items: center;
  @media (max-width: 1200px) {
    flex-direction: column;
    gap: 4px;
  }
`;

const StyledButton = styled.a`
  display: flex;
  width: 452px;
  height: 48px;
  padding: 0px 24px;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  border: 2px solid var(--Charcoal, #1f2024);
  background: var(--Yellow, #ffca3c);
  color: var(--Charcoal, #1f2024);
  font-size: 20px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  text-decoration: none;
  @media (max-width: 1200px) {
    width: 280px;
    padding: 0px;
  }
  @media (max-width: 380px) {
    width: 220px;
    font-size: 16px;
    padding: 0px;
  }
`;

function SocialFooter() {
  const { isTablet } = useMatchBreakPoints();

  return (
    <Container padding="24px 24px 72px 24px">
      <Row width="100%" justify="center">
        <StyledCard>
          <AutoRow width="100%" height="100%" justify="center" align="center">
            <ImgWrapper>
              <StyledImg src={LogoGIB} />
            </ImgWrapper>
            <Column
              width={isTablet ? "100%" : "fit-content"}
              height="100%"
              justify="center"
              align="center"
              gap="14px"
              m="24px"
            >
              {[
                { title: "TWITTER", url: SOCIAL_INFO.twitter },
                { title: "TELEGRAM", url: SOCIAL_INFO.telegram },
                {
                  title: "BUY ON QUICKSWAP",
                  url: BUY_ON_URL,
                },
              ].map((social) => {
                return (
                  <StyledButton href={social.url} target="_blank">
                    {social.title}
                  </StyledButton>
                );
              })}
            </Column>
          </AutoRow>
        </StyledCard>
      </Row>
    </Container>
  );
}

export default SocialFooter;
