import React from "react";
import { Column, Container, Grid, Row } from "../../../components/Layout";
import { Text } from "../../../components/Text";
import Button from "../../../components/Button/MainButton";
import ArrowIcon from "../../../public/images/icons/arrow.svg";
import CopyIcon from "../../../public/images/icons/copy.svg";
import Spacer from "../../../components/Spacer";

import { AutoRow } from "../../../components/Layout/Row";
import useMatchBreakPoints from "../../../utils/useMatchBreakPoints";
import UnderlineIcon, { UnderlineIcon3 } from "../../../components/Icons";
import { BUY_ON_URL, TOKEN_CONTRACT_ADDRESS } from "../../../config/constant";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { shortenAddress } from "../../../utils";
import BuyMaticGuideCardList from "./buy-matic-guid-card";

function HowToBuyMatic() {
  const { isTablet, isMobile } = useMatchBreakPoints();

  return (
    <Row width="100%" justify="center" align="center">
      <Container width="100%" py={isMobile || isTablet ? "0px" : "48px"}>
        <Column width="100%" justify="center" align="center">
          <Column width="100%" justify="center" align="center">
            <Text
              fontSize={isMobile ? "17px" : "20px"}
              textTransform="uppercase"
              fontWeight={900}
              lineHeight={"22.85px"}
              fontFamily="PPRightGrotesk"
            >
              SWAP YOUR CRYPTO FOR MATIC
            </Text>
            <UnderlineIcon3 width={isMobile || isTablet ? "300px" : "400px"} />
          </Column>
          <Column
            width={isMobile || isTablet ? "345px" : "420px"}
            padding="8px 0px"
          >
            <Text
              fontSize={isMobile ? "14px !important" : "16px"}
              fontWeight={400}
              lineHeight="23.7px"
              fontFamily="Space Mono"
              textAlign="center"
              paddingLeft="10px"
            >
              Follow these 4 simple steps to get MATIC in your wallet using
              SimpleSwap.
            </Text>
          </Column>

          <Spacer />
          <AutoRow
            width="100%"
            justify="center"
            gap={isTablet ? "4px" : "10px"}
          >
            <a
              href="https://simpleswap.io/"
              target="_blank"
              style={{ textDecoration: "none" }}
            >
              <Button
                isPrimary
                textProps={{ textTransform: "uppercase", bold: true }}
                endIcon={<img src={ArrowIcon} />}
              >
                GET MATIC ON SIMPLESWAP
              </Button>
            </a>
          </AutoRow>
          <ToastContainer />
          <Spacer size="lg" />
          <Spacer size="lg" />
          <Spacer size="lg" />
          <BuyMaticGuideCardList />
          <Spacer />
        </Column>
      </Container>
    </Row>
  );
}

export default HowToBuyMatic;
