import styled from "styled-components";
import Card from "../../../components/Card/Card";
import { Column, Container, Flex, Grid } from "../../../components/Layout";
import { Text } from "../../../components/Text";
import GibQuickSwapImg from "./../../../public/images/getmatic/gib-quickswap.png";
import QuickSwapAddressImg from "./../../../public/images/getmatic/quickswap-address.png";
import GibSendImg from "./../../../public/images/getmatic/gib-send.png";
import SwapMaticPictomethImg from "./../../../public/images/getmatic/swap-matic-pictometh.png";

import useMatchBreakPoints from "../../../utils/useMatchBreakPoints";
import UnderlineIcon, {
  UnderlineIcon2,
  UnderlineIcon3,
} from "../../../components/Icons";

const StyledCard = styled(Card)<{ rotate?: number; bgColor: string }>`
  gap: 10px;
  display: flex;
  width: 340px;
  max-width: 345px;
  height: 365px;
  transform: ${({ rotate }) => `rotate(${rotate}deg)`};
  justify-content: center;
  align-items: flex-start;
  align-self: stretch;
  background: ${({ bgColor }) => bgColor ?? ""};
  position: relative;
  overflow: visible;
  @media (max-width: 1200px) {
    transform: rotate(0deg);
    height: 344px;
    width: 345px;
  }
`;

const StyledImg = styled.img`
  border-radius: 16px;
  width: 283.8px;
  height: 172px;
  padding: 24px 24px 0px 24px;
  position: absolute;
  top: -70px;
  left: 0;
  z-index: 20;
  // background: url(<path-to-image>), lightgray 50% / cover no-repeat;
`;

const StyledCol = styled.div`
  display: flex;
  padding: 24px 24px 48px 24px;
  flex-direction: column;
  align-items: left;
  gap: 12px;
  align-self: stretch;
  padding-top: 176px;
  text-align: left;
`;

const GUIDE_DATA: {
  imgUrl: string;
  title: string;
  desc: string;
  rotate: number;
  bgColor: string;
  underlineWidth: string;
}[] = [
  {
    imgUrl: GibQuickSwapImg,
    title: "GO TO SIMPLESWAP",
    desc: "Head to SimpleSwap.io. Select the crypto you would like to swap from and select MATIC (Polygon) as the token you would like to swap to. Enter the amount you want to swap.",
    rotate: -2,
    bgColor: "#FE7649",
    underlineWidth: "100%",
  },
  {
    imgUrl: QuickSwapAddressImg,
    title: "ENTER YOUR ADDRESS",
    desc: "Now you need to enter your Polygon address. Be extremely careful and double-check your address. Your MATIC will be sent to this address right after the exchange.",
    rotate: 1,
    bgColor: "#FE994E",
    underlineWidth: "100%",
  },
  {
    imgUrl: GibSendImg,
    title: "SEND CRYPTO",
    desc: "On the exchange page, you will see the address to send the indicated amount of crypto to continue the exchange.",
    rotate: -1,
    bgColor: "#FFCA3C",
    underlineWidth: "70%",
  },
  {
    imgUrl: SwapMaticPictomethImg,
    title: "RECEIVE MATIC",
    desc: "When the swap process is over and the exchange is successfully finished, you will get your MATIC.",
    rotate: 2,
    bgColor: "#C292FF",
    underlineWidth: "70%",
  },
];

function GetMaticGuideCard({
  imgUrl,
  title,
  desc,
  rotate,
  bgColor,
  underlineWidth,
}: {
  imgUrl: string;
  title: string;
  desc: string;
  rotate: number;
  bgColor: string;
  underlineWidth: string;
}) {
  const { isMobile, isTablet } = useMatchBreakPoints();
  return (
    <StyledCard rotate={rotate} bgColor={bgColor}>
      <StyledImg src={imgUrl} />
      <StyledCol>
        <Text
          fontSize="20px"
          textTransform="uppercase"
          fontWeight={900}
          textAlign="left"
          fontFamily="PPRightGrotesk"
        >
          {title}
        </Text>
        <UnderlineIcon width={isMobile || isTablet ? underlineWidth : "100%"} />
        <Text
          fontSize="14px"
          fontWeight={400}
          lineHeight={"normal"}
          textAlign="left"
          fontFamily="Space Mono"
        >
          {desc}
        </Text>
      </StyledCol>
    </StyledCard>
  );
}

function BuyMaticGuideCardList() {
  const { isTablet } = useMatchBreakPoints();

  return (
    <Flex width="100%" justifyContent="center" alignItems="center">
      <Grid
        gridTemplateColumns={isTablet ? "1fr" : "1fr 1fr 1fr 1fr"}
        gridRowGap="70px"
      >
        {GUIDE_DATA.map((data) => (
          <GetMaticGuideCard {...data} />
        ))}
      </Grid>
    </Flex>
  );
}

export default BuyMaticGuideCardList;
