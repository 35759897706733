import styled from "styled-components";
import Card from "../../../components/Card/Card";
import { Column, Container } from "../../../components/Layout";
import Row from "../../../components/Layout/Row";
import Spacer from "../../../components/Spacer";
import { Text } from "../../../components/Text";
import AvtarGIB from "../../../public/gif/smoking.gif";
import useMatchBreakPoints from "../../../utils/useMatchBreakPoints";
import UnderlineIcon from "../../../components/Icons";

const StyledCard = styled(Card)`
  display: flex;
  width: 1024px;
  height: 480px;
  transform: rotate(-0.25deg);
  justify-content: center;
  align-items: flex-start;
  gap: 48px;
  flex-shrink: 0;
  border-radius: 32px;
  border: 3px solid var(--Charcoal, #1f2024);
  background: var(--White, #fff);
  /* Shadow */
  box-shadow: 0px 11px 15px 0px rgba(5, 5, 5, 0.2),
    0px 24px 38px 0px rgba(5, 5, 5, 0.14), 0px 4px 4px 0px rgba(5, 5, 5, 0.25);

  @media (max-width: 1200px) {
    width: 100%;
    height: 100%;
  }
`;

const ImgWrapper = styled.div`
  display: flex;
  height: 587px;
  padding: 10px 0px 10px 48px;
  justify-content: flex-end;
  align-items: flex-end;
  flex: 1 0 0;
  @media (max-width: 1200px) {
    padding: 0px;
    height: 257px;
  }
`;

const StyledImg = styled.img`
  width: 478.169px;
  height: 478.169px;
  position: absolute;
  right: -34.951px;
  top: 5.164px;
  @media (max-width: 1200px) {
    width: 345px;
    height: 349px;
    position: relative;
    right: auto;
    top: 50px;
  }
`;

const AutoRow = styled(Row)`
  @media (max-width: 1200px) {
    flex-direction: column;
  }
`;

function WhoIsGib() {
  const { isTablet } = useMatchBreakPoints();

  return (
    <Container padding="24px 24px 72px 24px">
      <Row width="100%" justify="center" p="0px" m="0px">
        <StyledCard background="#FFF">
          <AutoRow width="100%" height="100%" justify="center" align="center">
            <Column
              width="fit-content"
              justify="center"
              align="flex-start"
              gap="24px"
              padding={isTablet ? "32px 32px 0px 32px" : "auto"}
              paddingRight={isTablet ? "0px" : "48px"}
              marginLeft={isTablet ? "0px" : "96px"}
            >
              <Text
                fontSize="20px"
                fontWeight={700}
                lineHeight={"normal"}
                textAlign="left"
                fontFamily="Space Mono"
              >
                When life gives you lemons...
              </Text>
              <Column
                width="100%"
                justify="flex-start"
                align="left"
                gap={isTablet ? "4px" : "12px"}
              >
                <Text
                  fontSize="14px"
                  fontWeight={400}
                  lineHeight={"normal"}
                  fontFamily="Space Mono"
                >
                  STEP 1
                </Text>
                <Text
                  bold
                  fontSize="36px"
                  textTransform="uppercase"
                  fontWeight={900}
                  lineHeight={isTablet ? "1.5" : "0.5"}
                >
                  MAKE MEMES
                </Text>
                <UnderlineIcon color="#FFCA3C" width="65%" />
                <Spacer size="sm" />

                <Text
                  fontSize="14px"
                  fontWeight={400}
                  lineHeight={"normal"}
                  fontFamily="Space Mono"
                >
                  STEP 2
                </Text>
                <Text
                  bold
                  fontSize="36px"
                  textTransform="uppercase"
                  fontWeight={900}
                  lineHeight={isTablet ? "1.5" : "0.5"}
                >
                  $GIB UP HUMANITY
                </Text>
                <UnderlineIcon color="#FE994E" width="89%" />
                <Spacer size="sm" />
                <Text
                  fontSize="14px"
                  fontWeight={400}
                  lineHeight={"normal"}
                  fontFamily="Space Mono"
                >
                  STEP 3
                </Text>
                <Text
                  bold
                  fontSize="36px"
                  textTransform="uppercase"
                  fontWeight={900}
                  lineHeight={isTablet ? "1.5" : "0.5"}
                >
                  RETURN TO MONKEY
                </Text>
                <UnderlineIcon color="#FE7649" width="100%" />
              </Column>
            </Column>
            <ImgWrapper>
              <StyledImg src={AvtarGIB} />
            </ImgWrapper>
          </AutoRow>
        </StyledCard>
      </Row>
    </Container>
  );
}

export default WhoIsGib;
